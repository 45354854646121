import React, { useState, useEffect } from "react";
import CopyIcon from "../../assets/img/copy.svg";
import TrashIcon from "../../assets/img/trash.svg";
import MemberIcon from "../../assets/img/member.svg";

import { Field } from "formik";

const EventTimesItem = (props) => {
    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };
    const rangeMinutes = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => {
                return 15 * idx;
            });
    };
    return (
        <div className="event__times-box-item" data-key={props.key}>
            <div className="eti__medium">
                <div className="eti__select">
                    <Field as="select" name={props.hourName} id="">
                        {range(1, 24).map((item, index) => {
                            return (
                                <option
                                    selected={
                                        props.hour && props.hour === item
                                            ? true
                                            : ""
                                    }
                                    value={item}
                                >
                                    {item}
                                </option>
                            );
                        })}
                    </Field>
                </div>
                <div className="eti__separator">:</div>
                <div className="eti__select">
                    <Field as="select" name={props.minuteName} id="">
                        {rangeMinutes(1, 4).map((item, index) => {
                            return (
                                <option
                                    selected={
                                        props.minute && props.minute === item
                                            ? true
                                            : ""
                                    }
                                    value={item}
                                >
                                    {item}
                                </option>
                            );
                        })}
                    </Field>
                </div>
            </div>
            <div className="eti__small">
                <div className="eti__select">
                    <Field as="select" name={props.durationName} id="">
                        {rangeMinutes(1, 9).map((item, index) => {
                            if (index == 0) {
                                return;
                            }
                            return (
                                <option
                                    selected={
                                        props.duration &&
                                        props.duration === item
                                            ? true
                                            : ""
                                    }
                                    value={item}
                                >
                                    {item}
                                </option>
                            );
                        })}
                    </Field>
                </div>
            </div>
            <div className="eti__small">
                <div className="eti__select">
                    <Field as="select" name={props.slotName} id="">
                        {range(1, 10).map((item, index) => {
                            return (
                                <option
                                    selected={
                                        props.slot && props.slot === item
                                            ? true
                                            : ""
                                    }
                                    value={item}
                                >
                                    {item}
                                </option>
                            );
                        })}
                    </Field>
                </div>
            </div>
            <div className="eti__small">
                <div onClick={props.onCopy} className="eti__copy">
                    <img src={CopyIcon} alt="" />
                </div>
                <div
                    onClick={props.onMembers}
                    className={`eti__members ${
                        props.moderator && props.observers
                            ? "eti__members--green"
                            : ""
                    } ${
                        props.moderator && !props.observers
                            ? "eti__members--orange"
                            : ""
                    }`}
                >
                    <img src={MemberIcon} />
                </div>
                <div onClick={props.onRemove} className="eti__delete">
                    <img src={TrashIcon} alt="" />
                </div>
            </div>
        </div>
    );
};

export default EventTimesItem;
