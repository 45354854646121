import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { userLogin } from "../../../features/userSlice";
import { clearMessage } from "../../../features/messageSlice";

import panelLogoIcon from "../../../assets/img/panel_logo.svg";

const Login = (props) => {
    const [loading, setLoading] = useState(false);

    const { message } = useSelector((state) => state.message);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const initialValues = {
        email: "",
        password: "",
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required("This field is required"),
        password: Yup.string().required("This field is required"),
    });

    const handleLogin = (formValue) => {
        const { email, password } = formValue;
        setLoading(true);

        dispatch(
            userLogin({
                email,
                password,
            })
        )
            .unwrap()
            .then(() => {
                props.history.push("/");
                window.location.reload();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <div className="login__page">
            <div className="login__page-text">
                <img src={panelLogoIcon} alt="" />
                <h4>Welcome to [PRODUCT NAME]</h4>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                </p>
            </div>
            <div className="login__page-form">
                <h4>Log in to your account</h4>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                >
                    {({ values, touched, errors, handleChange }) => {
                        return (
                            <Form>
                                <input
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Enter your email"
                                />
                                <input
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    type="password"
                                    placeholder="Enter your password"
                                />
                                <button type="submit" name="submit">
                                    Log in
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
                <ul>
                    <li>
                        <a href="#">Can't log in</a>
                    </li>
                    <li>
                        <a href="#">Sign up for an account</a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Login;
