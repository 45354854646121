import { authHeader } from "./authHeader";
import { API_URL } from "../config";

const API = `${API_URL}/companies`;

const create = (data) => {
    return fetch(`${API}/create`, {
        method: "POST",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const update = (id, data) => {
    return fetch(`${API}/${id}`, {
        method: "PUT",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const get = (id) => {
    return fetch(`${API}/single/${id}`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const my = async () => {
    return fetch(`${API}/my`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const companiesService = {
    create,
    get,
    update,
    my,
};

export default companiesService;
