export const authHeader = (auth = false, fileupload = false) => {
    const token = localStorage.getItem("token");
    let headers = {};

    if (fileupload === false) {
        headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    }

    if (!auth) {
        return headers;
    }

    if (token) {
        return { ...headers, Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
};
