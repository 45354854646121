import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";

import HomeIcon from "../../assets/img/home-icon-breadcrumb.svg";
import PlayWhiteIcon from "../../assets/img/play-white.svg";
import SettingsWhiteIcon from "../../assets/img/settings-white.svg";
import SettingsGrayIcon from "../../assets/img/settings-gray.svg";
import ArrowDownGrayIcon from "../../assets/img/arrow-down-gray.svg";
import CopyIcon from "../../assets/img/copy.svg";
import TrashIcon from "../../assets/img/trash.svg";
import ArrowDownOrangeIcon from "../../assets/img/arrow-down-orange.svg";
import CloseWhiteIcon from "../../assets/img/close-white.svg";
import homeIcon from "../../assets/img/home-icon-breadcrumb.svg";
import playWhiteIcon from "../../assets/img/play-white.svg";
import closeWhiteIcon from "../../assets/img/close-white.svg";

import EventTimesBox from "../../components/EventTimesBox";
import EventTimesItem from "../../components/EventTimesItem";
import Modal from "../../common/Modal";

import eventsService from "../../services/event.service";
import invitesService from "../../services/invite.service";

import moment from "moment";
import { exists } from "i18next";
import { current } from "@reduxjs/toolkit";

import { EVENT_URL } from "../../config";

const EventTimes = (props) => {
    const eventId = props.match.params.id;
    const ref = useRef(null);
    const sidebarRef = useRef(null);
    const { user } = useSelector((state) => state.user);
    const [showModal, setShowModal] = useState(false);
    const [invites, setInvites] = useState([]);
    const [showClone, setShowClone] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [dates, setDates] = useState([]);
    const [event, setEvent] = useState({});
    const [showSidebar, setShowSidebar] = useState(false);

    const [userToEdit, setUserToEdit] = useState(0);
    const [inviteEdit, setInviteEdit] = useState(false);
    const [inviteNew, setInviteNew] = useState(false);

    const [currentDate, setCurrentDate] = useState("");
    const [currentTime, setCurrentTime] = useState("");

    const [editInviteInitialValues, setEditInviteInitialValues] = useState({
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        date: "",
        time: "",
    });
    const [inviteInitialValues, setInviteInitialValues] = useState({
        first_name: "",
        last_name: "",
        event: eventId,
        email: "",
        role: "",
        date: "",
        time: "",
    });
    const eventDateInitiaal = {
        date: "",
    };
    const eventDateValidationSchema = Yup.object().shape({
        date: Yup.string().required("This field is required"),
    });
    const [cloneEventDateInitiaal, setCloneEventDateInitiaal] = useState({
        dateid: "",
        date: "",
    });
    const cloneEventDateValidationSchema = Yup.object().shape({
        date: Yup.string().required("This field is required"),
    });
    const [changeDateInit, setChangeDateInit] = useState({
        dateid: "",
        dateindex: "",
        date: "",
    });
    const changeDateScheem = Yup.object().shape({
        date: Yup.string().required("This field is required"),
    });

    useEffect(() => {
        void (async function fetchData() {
            await eventsService.dates(eventId).then((resJson) => {
                setDates(resJson);
            });
            await eventsService.get(eventId).then((resJson) => {
                setEvent(resJson);
            });
        })();
    }, []);

    const addTime = async (formValues) => {
        const dateId = formValues.dateid;

        await eventsService.addTime(dateId, formValues).then((resJson) => {
            alert("Saved");
        });
    };
    const addDate = async (formValues) => {
        formValues.date = moment(formValues.date).format("DD/MM/YYYY");
        await eventsService
            .createDate({
                event: eventId,
                date: formValues.date,
            })
            .then((resJson) => {
                setDates((oldDates) => [...oldDates, resJson]);
                setShowModal(false);
            });
    };
    const cloneSetup = async (dateId) => {
        setCloneEventDateInitiaal({
            dateid: dateId,
            date: "",
        });
        setShowClone(true);
    };
    const handleCloneSetup = async (formValues) => {
        const dateId = formValues.dateid;
        formValues.date = moment(formValues.date).format("DD/MM/YYYY");
        await eventsService
            .cloneDate(dateId, { date: formValues.date })
            .then((resJson) => {
                setDates((oldDates) => [...oldDates, resJson]);
                setShowClone(false);
            });
    };
    const dateChange = async (indx, data) => {
        setChangeDateInit({
            dateid: data._id,
            dateindex: indx,
            date: data.date,
        });
        setEditModal(true);
    };
    const handleDateChange = async (formValues) => {
        const dateId = formValues.dateid;
        await eventsService
            .updateDate(dateId, { date: formValues.date })
            .then((resJson) => {
                let newDates = [...dates];
                newDates[formValues.dateindex] = resJson;
                setDates(newDates);
                setEditModal(false);
            });
    };
    const deleteDate = async (id, indx) => {
        await eventsService.deleteDate(id).then((resJson) => {
            let newDates = [...dates];
            newDates.splice(indx, 1);
            setDates(newDates);
        });
    };
    const sendEmail = () => {};

    const editUser = (item) => {
        setEditInviteInitialValues({
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email,
            role: item.role,
        });
        setUserToEdit(item._id);
        setInviteEdit(true);
    };

    const sendInvite = async (formValue) => {
        if (formValue.date === "") formValue.date = currentDate;
        if (formValue.time === "") formValue.time = currentTime;
        formValue.event = eventId;
        await invitesService.create(formValue).then((resJson) => {
            setInvites([...invites, resJson]);
            setInviteNew(false);
        });
    };
    const editInvite = async (formValue) => {
        await invitesService.update(userToEdit, formValue).then((resJson) => {
            setInviteEdit(false);
            window.location.reload();
        });
    };
    const deleteInvite = async (id) => {
        await invitesService.remove(id).then((resJson) => {
            setInvites((prevInvites) =>
                prevInvites.filter((inv) => inv._id !== id)
            );
        });
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (
                showSidebar &&
                sidebarRef.current &&
                !sidebarRef.current.contains(e.target)
            ) {
                setShowSidebar(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showSidebar]);

    const handleOnMembers = async (date, id) => {
        setInvites([]);
        setShowSidebar(true);
        setCurrentDate(date);
        setCurrentTime(id);
        await invitesService
            .getByDateTime(eventId, date, id)
            .then((resJson) => {
                setInvites(resJson);
            });
    };
    return (
        <>
            <div className="settings event__settings-tab">
                <Modal show={showModal}>
                    <div className="app__modal-title">
                        <h4>Add New Date</h4>
                        <img
                            className="modal--close"
                            src={CloseWhiteIcon}
                            alt=""
                        />
                    </div>
                    <div className="app__modal-content">
                        <Formik
                            initialValues={eventDateInitiaal}
                            validationSchema={eventDateValidationSchema}
                            onSubmit={addDate}
                        >
                            {({ values, touched, errors, handleChange }) => {
                                return (
                                    <Form>
                                        <div className="custom__input">
                                            <label for="">Event date</label>
                                            <input
                                                type="date"
                                                name="date"
                                                value={values.date}
                                                onChange={handleChange}
                                                placeholder="Enter Event Date"
                                            />
                                        </div>
                                        <div className="app__modal-bottom">
                                            <button
                                                onClick={() =>
                                                    setShowModal(false)
                                                }
                                                className="button-o"
                                                type="button"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" name="submit">
                                                Create
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </Modal>
                <Modal show={showClone}>
                    <div className="app__modal-title">
                        <h4>Cloned setup date</h4>
                        <img
                            className="modal--close"
                            src={CloseWhiteIcon}
                            alt=""
                        />
                    </div>
                    <div className="app__modal-content">
                        <Formik
                            initialValues={cloneEventDateInitiaal}
                            enableReinitialize={true}
                            validationSchema={cloneEventDateValidationSchema}
                            onSubmit={handleCloneSetup}
                        >
                            {({ values, touched, errors, handleChange }) => {
                                return (
                                    <Form>
                                        <Field
                                            type="hidden"
                                            name="dateid"
                                            value={values.dateid}
                                        />
                                        <div className="custom__input">
                                            <label for="">Event date</label>
                                            <input
                                                type="date"
                                                name="date"
                                                value={values.date}
                                                onChange={handleChange}
                                                placeholder="Enter Event Date"
                                            />
                                        </div>
                                        <div className="app__modal-bottom">
                                            <button
                                                onClick={() =>
                                                    setShowClone(false)
                                                }
                                                className="button-o"
                                                type="button"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" name="submit">
                                                Clone
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </Modal>
                <Modal show={editModal}>
                    <div className="app__modal-title">
                        <h4>Edit setup date</h4>
                        <img
                            className="modal--close"
                            src={CloseWhiteIcon}
                            alt=""
                        />
                    </div>
                    <div className="app__modal-content">
                        <Formik
                            initialValues={changeDateInit}
                            enableReinitialize={true}
                            validationSchema={changeDateScheem}
                            onSubmit={handleDateChange}
                        >
                            {({ values, touched, errors, handleChange }) => {
                                return (
                                    <Form>
                                        <Field
                                            type="hidden"
                                            name="dateid"
                                            value={values.dateid}
                                        />
                                        <Field
                                            type="hidden"
                                            name="dateindex"
                                            value={values.dateindex}
                                        />
                                        <div className="custom__input">
                                            <label for="">Event date</label>
                                            <input
                                                type="text"
                                                name="date"
                                                value={values.date}
                                                onChange={handleChange}
                                                placeholder="Enter Event Date"
                                            />
                                        </div>
                                        <div className="app__modal-bottom">
                                            <button
                                                onClick={() =>
                                                    setEditModal(false)
                                                }
                                                className="button-o"
                                                type="button"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" name="submit">
                                                Save
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </Modal>

                <Modal show={inviteNew}>
                    <div class="app__modal-box">
                        <div class="app__modal-title">
                            <h4>Invite New User</h4>
                            <img
                                class="modal--close"
                                src={closeWhiteIcon}
                                alt=""
                            />
                        </div>
                        <div class="app__modal-content">
                            <Formik
                                initialValues={inviteInitialValues}
                                onSubmit={sendInvite}
                                enableReinitialize
                            >
                                {({
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                }) => {
                                    return (
                                        <Form>
                                            <input
                                                type="hidden"
                                                name="date"
                                                value={currentDate}
                                                style={{ display: "none" }}
                                            />
                                            <input
                                                type="hidden"
                                                name="time"
                                                value={currentTime}
                                                style={{ display: "none" }}
                                            />
                                            <div class="app__modal-big-input">
                                                <label for="">First Name</label>
                                                <input
                                                    name="first_name"
                                                    value={values.first_name}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </div>
                                            <div class="app__modal-big-input">
                                                <label for="">Last Name</label>
                                                <input
                                                    name="last_name"
                                                    value={values.last_name}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </div>
                                            <div class="app__modal-big-input">
                                                <label for="">Email</label>
                                                <input
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </div>
                                            <div class="app__modal-big-select">
                                                <label for="">Role</label>
                                                <div class="custom__select">
                                                    <select
                                                        name="role"
                                                        onChange={handleChange}
                                                        id=""
                                                    >
                                                        <option
                                                            selected
                                                            value="administrator"
                                                        >
                                                            Administrator
                                                        </option>
                                                        <option value="manager">
                                                            Manager
                                                        </option>
                                                        <option value="observer">
                                                            Observer
                                                        </option>
                                                        <option value="translator">
                                                            Translator
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="app__modal-bottom">
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        setInviteNew(false)
                                                    }
                                                    class="button-o"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    name="submit"
                                                >
                                                    Send Invite
                                                </button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </Modal>
                <Modal show={inviteEdit}>
                    <div class="app__modal-box">
                        <div class="app__modal-title">
                            <h4>Edit Invited User</h4>
                            <img
                                class="modal--close"
                                src={closeWhiteIcon}
                                alt=""
                            />
                        </div>
                        <div class="app__modal-content">
                            <Formik
                                enableReinitialize
                                initialValues={editInviteInitialValues}
                                onSubmit={editInvite}
                            >
                                {({
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                }) => {
                                    return (
                                        <Form>
                                            <div class="app__modal-big-input">
                                                <label for="">First name</label>
                                                <input
                                                    name="first_name"
                                                    value={values.first_name}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </div>
                                            <div class="app__modal-big-input">
                                                <label for="">Last Name</label>
                                                <input
                                                    name="last_name"
                                                    value={values.last_name}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </div>
                                            <div class="app__modal-big-input">
                                                <label for="">Email</label>
                                                <input
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </div>
                                            <div class="app__modal-big-select">
                                                <label for="">Role</label>
                                                <div class="custom__select">
                                                    <select
                                                        name="role"
                                                        onChange={handleChange}
                                                        defaultValue={
                                                            values.role
                                                        }
                                                        id=""
                                                    >
                                                        <option
                                                            selected={
                                                                values.role ===
                                                                "administrator"
                                                                    ? true
                                                                    : false
                                                            }
                                                            value="administrator"
                                                        >
                                                            Administrator
                                                        </option>
                                                        <option
                                                            selected={
                                                                values.role ===
                                                                "manager"
                                                                    ? true
                                                                    : false
                                                            }
                                                            value="manager"
                                                        >
                                                            Manager
                                                        </option>
                                                        <option
                                                            selected={
                                                                values.role ===
                                                                "observer"
                                                                    ? true
                                                                    : false
                                                            }
                                                            value="observer"
                                                        >
                                                            Observer
                                                        </option>
                                                        <option
                                                            selected={
                                                                values.role ===
                                                                "translator"
                                                                    ? true
                                                                    : false
                                                            }
                                                            value="translator"
                                                        >
                                                            Translator
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="app__modal-bottom">
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        setInviteEdit(false)
                                                    }
                                                    class="button-o"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    name="submit"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </Modal>
                <div className="event__settings-tab-top">
                    <div className="breadcrumb">
                        <ul>
                            <li>
                                <a href="/">
                                    <img src={HomeIcon} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="/events">Events</a>
                            </li>
                            <li>
                                <a href={`/event/${eventId}`}>{event?.name}</a>
                            </li>
                            <li>
                                <a href={`/event/${eventId}`}>Event Settings</a>
                            </li>
                            <li>
                                <a href={`/event-times/${eventId}`}>
                                    Event Sessions
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="event__settings-buttons">
                        <div className="custom__select">
                            <select name="" id="">
                                <option value="">View Event Page</option>
                            </select>
                            <div
                                onClick={() => {
                                    window
                                        .open(
                                            `${EVENT_URL}/${eventId}`,
                                            "_blank"
                                        )
                                        .focus();
                                }}
                                className="custom__select-button"
                            >
                                <img src={PlayWhiteIcon} alt="" />
                            </div>
                        </div>
                        <button
                            onClick={() =>
                                props.history.push(
                                    `/project-management/${eventId}`
                                )
                            }
                        >
                            Show Results
                        </button>
                        <button>Download Results</button>
                        <a href={`/event/${eventId}`}>
                            <button className="button__small">
                                <img src={SettingsWhiteIcon} alt="" />
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="event__times">
                <div className="event__times-title">
                    <h4>Event Times</h4>
                </div>
                <div className="event__times-top">
                    <div className="event__times-top-left">
                        <span>
                            <b>Your Default Time Zone:</b> (GMT-5:00) CT (New
                            York)
                        </span>
                    </div>
                    <div className="event__times-top-right">
                        <div className="custom__select">
                            <select name="" id="">
                                <option value="all">Show All</option>
                            </select>
                        </div>
                        <div className="custom__select">
                            <select name="" id="">
                                <option value="asc">Date Ascending</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="event__times-boxes">
                    {dates.map((datItem, datIndex) => {
                        return (
                            <EventTimesBox
                                key={datIndex}
                                date={datItem.date}
                                onCloneSetup={() => cloneSetup(datItem._id)}
                                onChangeDate={() =>
                                    dateChange(datIndex, datItem)
                                }
                                onDeleteSetup={() =>
                                    deleteDate(datItem._id, datIndex)
                                }
                            >
                                <Formik
                                    innerRef={ref}
                                    enableReinitialize={true}
                                    initialValues={{
                                        dateid: datItem._id,
                                        times: datItem.times,
                                    }}
                                    onSubmit={addTime}
                                >
                                    {({ values, handleChange }) => {
                                        return (
                                            <Form>
                                                <Field
                                                    type="hidden"
                                                    name="dateid"
                                                    value={datItem._id}
                                                />
                                                <FieldArray
                                                    name="times"
                                                    render={(arrayHelpers) => (
                                                        <div>
                                                            {values.times &&
                                                            values.times
                                                                .length > 0
                                                                ? values.times.map(
                                                                      (
                                                                          item,
                                                                          index
                                                                      ) => (
                                                                          <EventTimesItem
                                                                              key={
                                                                                  index
                                                                              }
                                                                              hourName={`times.${index}.hour`}
                                                                              minuteName={`times.${index}.minute`}
                                                                              durationName={`times.${index}.duration`}
                                                                              slotName={`times.${index}.slot`}
                                                                              moderator={
                                                                                  false
                                                                              }
                                                                              observers={
                                                                                  false
                                                                              }
                                                                              onCopy={() => {
                                                                                  arrayHelpers.insert(
                                                                                      index +
                                                                                          1,
                                                                                      ref
                                                                                          .current
                                                                                          .values
                                                                                          .times[
                                                                                          index
                                                                                      ]
                                                                                  );
                                                                              }}
                                                                              onMembers={() => {
                                                                                  console.log(
                                                                                      datItem
                                                                                  );
                                                                                  console.log(
                                                                                      "time id",
                                                                                      item._id
                                                                                  );
                                                                                  handleOnMembers(
                                                                                      datItem.date,
                                                                                      item._id
                                                                                  );
                                                                              }}
                                                                              onRemove={() => {
                                                                                  arrayHelpers.remove(
                                                                                      index
                                                                                  );
                                                                              }}
                                                                          />
                                                                      )
                                                                  )
                                                                : ""}

                                                            <div
                                                                onClick={() => {
                                                                    arrayHelpers.insert(
                                                                        ref
                                                                            .current
                                                                            .values
                                                                            .times
                                                                            .length +
                                                                            1,
                                                                        {
                                                                            hour: "1",
                                                                            minute: "0",
                                                                            duration:
                                                                                "15",
                                                                            slot: "1",
                                                                        }
                                                                    );
                                                                }}
                                                                className="event__times-box-item-add"
                                                            >
                                                                <a href="#">
                                                                    Add new time
                                                                </a>
                                                            </div>
                                                            <div className="event__times--save">
                                                                <button type="submit">
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </EventTimesBox>
                        );
                    })}
                </div>
                <div
                    onClick={() => setShowModal(true)}
                    className="event__times-add-box"
                >
                    <a href="#">+New Date</a>
                </div>
            </div>
            <div className="app__right app__right--hidden">
                <div className="app__right-content">
                    <div className="app__right-box">
                        <h3>Data Overview</h3>
                        <div className="custom__select">
                            <select name="" id="">
                                <option value="all">Show all</option>
                            </select>
                        </div>
                        <div className="custom__select">
                            <select name="" id="">
                                <option value="asc">Date Ascending</option>
                            </select>
                        </div>
                    </div>
                    <div className="app__right-box">
                        <h4>Dates</h4>
                        <ul>
                            <li className="full-li">[DATE]</li>
                            <li>Time: HH:MM</li>
                            <li>Slots: [##]/[##]</li>
                            <li>Time: HH:MM</li>
                            <li>Slots: [##]/[##]</li>
                            <li>Time: HH:MM</li>
                            <li>Slots: [##]/[##]</li>
                            <li>Time: HH:MM</li>
                            <li>Slots: [##]/[##]</li>
                            <li>Time: HH:MM</li>
                            <li>Slots: [##]/[##]</li>
                            <li>Time: HH:MM</li>
                            <li>Slots: [##]/[##]</li>
                        </ul>
                        <ul>
                            <li className="full-li">[DATE]</li>
                            <li>Time: HH:MM</li>
                            <li>Slots: [##]/[##]</li>
                            <li>Time: HH:MM</li>
                            <li>Slots: [##]/[##]</li>
                            <li>Time: HH:MM</li>
                            <li>Slots: [##]/[##]</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div
                ref={sidebarRef}
                class={`app__right ${!showSidebar && "app__right--hidden"}`}
            >
                <div className="app__right-content">
                    <div className="app__right-box">
                        <h4>Send Link To Selected</h4>
                        <div className="app__right-box-button">
                            <button onClick={() => sendEmail()}>
                                Send Email
                            </button>
                            <button>Send SMS</button>
                        </div>
                    </div>
                    <div className="app__right-box">
                        <h4>Assign Moderator</h4>
                        <div className="custom__select">
                            <select name="" id="">
                                <option value="asc">{user.name}</option>
                            </select>
                        </div>
                        <div className="custom__check">
                            <div className="custom__checkbox">
                                <label className="custom__checkbox-container">
                                    <input
                                        type="checkbox"
                                        name="moderator_selected"
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="custom__check-content">
                                <h5>Select Moderator</h5>
                            </div>
                        </div>
                    </div>
                    <div className="app__right-box">
                        <h4>Assign Observers</h4>
                        {invites.map((item, index) => {
                            return (
                                <div className="custom__check">
                                    <div className="custom__checkbox">
                                        <label className="custom__checkbox-container">
                                            <input
                                                type="checkbox"
                                                name="enabled"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="custom__check-content">
                                        <h5>{item.name}</h5>
                                        <h5>{item.email}</h5>
                                        <div className="custom__check-buttons">
                                            <button
                                                onClick={() => editUser(item)}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() =>
                                                    deleteInvite(item._id)
                                                }
                                                className="cc--delete"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <div className="new__observer">
                            <a onClick={() => setInviteNew(true)} href="#">
                                + Add New Observer Here
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventTimes;
