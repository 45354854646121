import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { user, token } = useSelector((state) => state.user);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (user && token) {
                    return <Component {...rest} {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                            }}
                        />
                    );
                }
            }}
        />
    );
};

export default ProtectedRoute;
