import React, { useState, useEffect } from "react";

import moreIcon from "../../assets/img/more.svg";
import videoGraphyIcon from "../../assets/img/video-graphy.svg";
import sendIcon from "../../assets/img/send.svg";

import Participant from "../../common/Participant";

import eventsService from "../../services/event.service";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import Video from "twilio-video";

const config = {
    apiKey: "AIzaSyAhjIWaVfALaPS3qsM1lGcJ9IOaG61cryE",
    authDomain: "dkmt-api.firebaseapp.com",
    projectId: "dkmt-api",
    storageBucket: "dkmt-api.appspot.com",
    messagingSenderId: "779458593706",
    appId: "1:779458593706:web:7ae2d9005e06913a6461d3",
};
firebase.initializeApp(config);
const db = firebase.firestore();

const VideoComponent = (props) => {
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);

    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);

    const [token, setToken] = useState(null);
    const [roomName, setRoomName] = useState(props.match.params.id);
    const [userId, setUserId] = useState(props.match.params.user);

    const query = db.collection("chats").doc(roomName).collection("messages");
    const handleMessage = () => {
        sendMessage(message);
    };

    // const [remoteParticipants, setRemoteParticipants] = useState([]);
    const remoteParticipants = participants?.map((participant) => {
        console.log("PARTO", participant);
        return <Participant key={participant.sid} participant={participant} />;
    });
    useEffect(() => {
        void (async function fetchData() {
            await eventsService
                .videoGrant({
                    videoId: roomName,
                    userId: userId,
                })
                .then((resJson) => {
                    setToken(resJson.token);
                })
                .catch((e) => console.log(e));
        })();

        const unsubscribe = query
            .orderBy("time", "asc")
            .onSnapshot((querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                });
                setMessages(data);
            });
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (token && roomName) {
            const participantConnected = (participant) => {
                let newArr = [...participants];
                newArr.push(participant);
                setParticipants(newArr);
                // setParticipants((prevParticipants) => [
                //     ...prevParticipants,
                //     participant,
                // ]);
            };

            const participantDisconnected = (participant) => {
                let newArr = participants.filter((p) => p !== participant);
                setParticipants(newArr);
                // setParticipants((prevParticipants) => {
                //     prevParticipants.filter((p) => p !== participant);
                // });
            };

            const roomDisconnected = (r) => {
                r.localParticipant.tracks.forEach((trackPub) => {
                    trackPub.track.stop();
                });

                r.disconnect();
                setRoom(null);
            };

            const videoConfig = {
                name: roomName,
                _useTwilioConnection: true,
                video: false,
                audio: true,
                logLevel: "debug",
            };

            navigator.getUserMedia =
                navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia;

            if (navigator.getUserMedia) {
                navigator.getUserMedia(
                    { audio: true, video: true },
                    (stream) => {
                        videoConfig.video = {
                            height: 720,
                            frameRate: 24,
                            width: 1200,
                        };
                        videoConfig.audio = true;
                        Video.connect(token, videoConfig)
                            .then(async (room) => {
                                setRoom(room);
                                room.on(
                                    "participantConnected",
                                    participantConnected
                                );
                                room.on(
                                    "participantDisconnected",
                                    participantDisconnected
                                );
                                room.on("disconnected", roomDisconnected);

                                await room.participants.forEach(
                                    participantConnected
                                );
                            })
                            .catch((err) => {
                                console.log(err, { message: err.message });
                            });
                    },
                    (err) => {
                        console.log("NGUM - Error", err);
                    }
                );
            }

            return () => {
                setRoom((currentRoom) => {
                    if (
                        currentRoom &&
                        currentRoom.localParticipant.state === "connected"
                    ) {
                        currentRoom.localParticipant.tracks.forEach(
                            (trackPublication) => {
                                trackPublication.track.stop();
                            }
                        );

                        currentRoom.disconnect();
                        return null;
                    } else {
                        return currentRoom;
                    }
                });
            };
        }
    }, [roomName, token]);

    const sendMessage = async (message) => {
        const trimmedMessage = message.trim();

        if (trimmedMessage) {
            query.doc().set({
                sender: userId,
                message: trimmedMessage,
                time: Date.now(),
            });
            // await chatService
            //     .send({
            //         chatId: props.match.params.id,
            //         message: trimmedMessage,
            //         time: Date.now(),
            //     })
            //     .then((resJson) => {
            //         // console.log(resJson);
            //     })
            //     .catch((e) => console.log("ERROR:", e));
            setMessage("");
        }
    };

    return (
        <div className="video__screen">
            <div className="video__content">
                <div className="video__content-box">
                    <div className="video__content-participants">
                        <div className="video__content-title">
                            <h3>[EVENT TITLE]</h3>
                        </div>
                        {remoteParticipants}

                        {room ? (
                            <Participant
                                key={room.localParticipant.sid}
                                participant={room.localParticipant}
                            />
                        ) : (
                            ""
                        )}
                        {/* <div className="video__content-participant participant--me">
                            <div className="video__content-participant-image">
                                <img src="../img/participant4.png" alt="" />
                            </div>
                            <div className="video__content-participant-name">
                                <h4>Erlina Watson</h4>
                                <img src={videoGraphyIcon} alt="" />
                            </div>
                            <div className="video__content-participant-more">
                                <div className="p-more-button">
                                    <img src={moreIcon} alt="" />
                                </div>
                            </div>
                            <div className="video__content-participant-role">
                                <span>Moderator</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="video__conversation">
                <div className="conversation__search">
                    <input type="text" placeholder="Search chat or anything" />
                    <img src="../img/search-gray.svg" alt="" />
                </div>
                <div className="conversation__title">
                    <h4>Chat rooms</h4>
                    <img src="../img/users.svg" alt="" />
                </div>
                <div className="conversation__body">
                    {messages.map((msg, index) => (
                        <div
                            key={index}
                            className={`message ${
                                msg.sender == userId ? "message--self" : ""
                            }`}
                        >
                            <div className="message__content">
                                <p>{msg.message}</p>
                            </div>
                            <div className="message__about">
                                <h5>Erlina to Everyone</h5>
                                <span>10:38 AM</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="conversation__send-message">
                    <input
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        type="text"
                        placeholder="Type as message..."
                    />
                    <img
                        onClick={() => handleMessage()}
                        src={sendIcon}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};

export default VideoComponent;
