import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import HomeIcon from "../../assets/img/home-icon-breadcrumb.svg";
import playWhiteIcon from "../../assets/img/play-white.svg";
import settingsWhiteIcon from "../../assets/img/settings-white.svg";

import eventsService from "../../services/event.service";

import { EVENT_URL } from "../../config";

const EventNotifications = (props) => {
    const eventId = props.match.params.id;
    const [notifications, setNotifications] = useState({});
    const [event, setEvent] = useState({});

    const [notificationsInitialValues, setNotificationsInitialValues] =
        useState({
            confirmation: {
                email: false,
                sms: false,
            },
            follow_up: {
                email: false,
                sms: false,
                frequencies: {
                    increment: 0,
                    unit: "",
                    method: "",
                },
            },
            reminders: {
                email: false,
                sms: false,
                frequencies: {
                    increment: 0,
                    unit: "",
                    method: "",
                },
            },
            cancellation: {
                email: false,
                sms: false,
            },
        });

    const saveNotifications = async (formValues) => {
        await eventsService
            .update(eventId, { notifications: formValues })
            .then((resJson) => {
                window.location.reload();
            });
    };

    useEffect(() => {
        void (async function fetchData() {
            await eventsService.get(eventId).then((resJson) => {
                setEvent(resJson);
                setNotifications(resJson.notifications);
                setNotificationsInitialValues(resJson.notifications);
            });
        })();
    }, []);

    return (
        <>
            <div class="settings event__settings-tab">
                <div class="event__settings-tab-top">
                    <div class="breadcrumb">
                        <ul>
                            <li>
                                <a href="getstarted.php">
                                    <img src={HomeIcon} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Events</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">[EVENT]</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Event Settings</a>
                            </li>
                        </ul>
                    </div>
                    <div class="event__settings-buttons">
                        <div class="custom__select">
                            <select name="" id="">
                                <option value="">View Event Page</option>
                            </select>
                            <div
                                onClick={() => {
                                    window
                                        .open(
                                            `${EVENT_URL}/${eventId}`,
                                            "_blank"
                                        )
                                        .focus();
                                }}
                                class="custom__select-button"
                            >
                                <img src={playWhiteIcon} alt="" />
                            </div>
                        </div>
                        <button>Donwload Results</button>
                        <button class="button__small">
                            <img src={settingsWhiteIcon} alt="" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="event__notifications">
                <div class="event__notifications-title">
                    <h3>Notification Method</h3>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={notificationsInitialValues}
                    onSubmit={saveNotifications}
                >
                    {({ values, touched, errors, handleChange }) => {
                        return (
                            <Form>
                                <div class="event__notifications-items">
                                    <div class="event__notifications-item">
                                        <div class="event__notifications-item-content">
                                            <h4>Confirmation</h4>
                                            <p>
                                                Your invite will receive a
                                                confirmation message directly
                                                after after the time ischeduled.
                                            </p>
                                        </div>
                                        <div class="event__notifications-item-checks">
                                            <div class="checks__switch">
                                                <div class="custom__switch">
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            name="confirmation.email"
                                                            checked={
                                                                values
                                                                    .confirmation
                                                                    .email
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <h5>Email</h5>
                                            </div>
                                            <div class="checks__switch">
                                                <div class="custom__switch">
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            name="confirmation.sms"
                                                            checked={
                                                                values
                                                                    .confirmation
                                                                    .sms
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <h5>SMS</h5>
                                            </div>
                                        </div>
                                        <div class="event__notifications-item-button"></div>
                                    </div>
                                    <div class="event__notifications-item">
                                        <div class="event__notifications-item-content">
                                            <h4>Follow Up</h4>
                                            <p>
                                                Your invite will receive a
                                                follow up messages at specific
                                                times after the scheduled event.
                                            </p>
                                        </div>
                                        <div class="event__notifications-item-checks">
                                            <div class="checks__switch">
                                                <div class="custom__switch">
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            name="follow_up.email"
                                                            checked={
                                                                values.follow_up
                                                                    .email
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <h5>Email</h5>
                                            </div>
                                            <div class="checks__switch">
                                                <div class="custom__switch">
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            name="follow_up.sms"
                                                            checked={
                                                                values.follow_up
                                                                    .sms
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <h5>SMS</h5>
                                            </div>
                                        </div>
                                        <div class="event__notifications-item-button">
                                            <button>Edit Frequency</button>
                                        </div>
                                    </div>
                                    <div class="event__notifications-item">
                                        <div class="event__notifications-item-content">
                                            <h4>Reminders</h4>
                                            <p>
                                                Your invite will receive a
                                                reminder messages at specific
                                                times.
                                            </p>
                                        </div>
                                        <div class="event__notifications-item-checks">
                                            <div class="checks__switch">
                                                <div class="custom__switch">
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            name="reminders.email"
                                                            checked={
                                                                values.reminders
                                                                    .email
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <h5>Email</h5>
                                            </div>
                                            <div class="checks__switch">
                                                <div class="custom__switch">
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            name="reminders.sms"
                                                            checked={
                                                                values.reminders
                                                                    .sms
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <h5>SMS</h5>
                                            </div>
                                        </div>
                                        <div class="event__notifications-item-button">
                                            <button>Edit Frequency</button>
                                        </div>
                                    </div>
                                    <div class="event__notifications-item">
                                        <div class="event__notifications-item-content">
                                            <h4>Cancellation</h4>
                                            <p>
                                                Your invite will receive a
                                                cancellation message when you
                                                cancel an appointment
                                            </p>
                                        </div>
                                        <div class="event__notifications-item-checks">
                                            <div class="checks__switch">
                                                <div class="custom__switch">
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            name="cancellation.email"
                                                            checked={
                                                                values
                                                                    .cancellation
                                                                    .email
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <h5>Email</h5>
                                            </div>
                                            <div class="checks__switch">
                                                <div class="custom__switch">
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            name="cancellation.sms"
                                                            checked={
                                                                values
                                                                    .cancellation
                                                                    .sms
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <h5>SMS</h5>
                                            </div>
                                        </div>
                                        <div class="event__notifications-item-button"></div>
                                    </div>

                                    <div className="event__notifications-button">
                                        <button type="submit" name="submit">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default EventNotifications;
