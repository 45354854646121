import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import Modal from "../../common/Modal";

import ArrowDownOrangeIcon from "../../assets/img/arrow-down-orange.svg";
import CloseWhiteIcon from "../../assets/img/close-white.svg";
import PaginationLeftIcon from "../../assets/img/pagination-left.svg";
import PaginationRightIcon from "../../assets/img/pagination-right.svg";

import eventsService from "../../services/event.service";
import usersService from "../../services/user.service";

import { EVENT_URL } from "../../config";

const Events = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [company, setCompany] = useState({});
    const [events, setEvents] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState("");

    const eventInitialValues = {
        name: "",
    };
    const eventValidationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required"),
    });

    const handleAddEvent = async (formValue) => {
        const { name } = formValue;
        addEvent(name);
    };

    useEffect(() => {
        void (async function fetchData() {
            await usersService.getCompany().then((resJson) => {
                setCompany(resJson);
            });
            await eventsService.all(company._id).then((resJson) => {
                setEvents(resJson);
            });
        })();
    }, []);

    const addEvent = async (name) => {
        await eventsService.create({ name }).then((resJson) => {
            setShowModal(false);
            props.history.push(`/event/${resJson.eventId}`);
        });
    };

    const toggleDropdown = (name) => {
        if (activeDropdown === name) {
            setActiveDropdown("");
        } else {
            setActiveDropdown(name);
        }
    };

    return (
        <div className="settings event__overview">
            <Modal show={showModal}>
                <div class="app__modal-title">
                    <h4>Add New Event</h4>
                    <img class="modal--close" src={CloseWhiteIcon} alt="" />
                </div>
                <div class="app__modal-content">
                    <Formik
                        initialValues={eventInitialValues}
                        validationSchema={eventValidationSchema}
                        onSubmit={handleAddEvent}
                    >
                        {({ values, touched, errors, handleChange }) => {
                            return (
                                <Form>
                                    <div class="custom__input">
                                        <label for="">Event Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="Enter Event Name"
                                        />
                                    </div>
                                    <div class="app__modal-bottom">
                                        <button
                                            onClick={() => setShowModal(false)}
                                            class="button-o"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" name="submit">
                                            Create
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
            <div className="event__overview-top">
                <button onClick={() => setShowModal(true)} className="ce">
                    Add New Event
                </button>
                <select name="" id="">
                    <option value="">Show All</option>
                </select>
                <select name="" id="">
                    <option value="">Date Ascending</option>
                </select>
            </div>
            <div className="event__overview-title">
                <h5>Events</h5>
            </div>
            <div className="settings__um-list">
                {events.map((item, index) => {
                    return (
                        <div className="settings__um-out">
                            <div className="settings__um-item">
                                <div className="umitem__top">
                                    <h5>{item.name}</h5>
                                </div>
                                <div className="umitem__content">
                                    <div className="umitem__content-left">
                                        <ul>
                                            <li>
                                                <span>Manager:</span>
                                                <em>[Manager Name]</em>
                                            </li>
                                            <li>
                                                <span>Booked:</span>
                                                <em>0</em>
                                            </li>
                                            <li>
                                                <span>Open:</span>
                                                <em>0</em>
                                            </li>
                                            <li>
                                                <span className="linked">
                                                    Time Zones:
                                                </span>
                                                <em>0</em>
                                            </li>
                                            <li>
                                                <span className="linked">
                                                    Languages:
                                                </span>
                                                <em>0</em>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="umitem__content-right">
                                        <div className="umitem__content-action">
                                            <span>Enable</span>
                                            <div className="custom__switch">
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            className={`umitem__content-action ${
                                                activeDropdown ===
                                                `${item._id}-1`
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                toggleDropdown(`${item._id}-1`)
                                            }
                                        >
                                            <span>Share</span>
                                            <img
                                                src={ArrowDownOrangeIcon}
                                                alt=""
                                            />

                                            <div class="action__list">
                                                <ul>
                                                    <li>
                                                        <b>Share Event</b>
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            window
                                                                .open(
                                                                    `${EVENT_URL}/${item._id}`,
                                                                    "_blank"
                                                                )
                                                                .focus();
                                                        }}
                                                        class="eup"
                                                    >
                                                        View Event Page
                                                    </li>
                                                    <li class="eur">
                                                        Copy Event Page Link
                                                    </li>
                                                    <li class="rup">
                                                        Copy Event API Key
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div
                                            className={`umitem__content-action ${
                                                activeDropdown ===
                                                `${item._id}-2`
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                toggleDropdown(`${item._id}-2`)
                                            }
                                        >
                                            <span>Results</span>
                                            <img
                                                src={ArrowDownOrangeIcon}
                                                alt=""
                                            />

                                            <div class="action__list">
                                                <ul>
                                                    <li>
                                                        <b>Event Results</b>
                                                    </li>
                                                    <li class="eup">
                                                        Result Page
                                                    </li>
                                                    <li class="eur">
                                                        Download Results
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div
                                            className={`umitem__content-action ${
                                                activeDropdown ===
                                                `${item._id}-3`
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                toggleDropdown(`${item._id}-3`)
                                            }
                                        >
                                            <span>Settings</span>
                                            <img
                                                src={ArrowDownOrangeIcon}
                                                alt=""
                                            />

                                            <div class="action__list">
                                                <ul>
                                                    <li>
                                                        <b>Event Settings</b>
                                                    </li>
                                                    <li
                                                        onClick={() =>
                                                            props.history.push(
                                                                `/event/${item._id}`
                                                            )
                                                        }
                                                        class="eup"
                                                    >
                                                        Edit Event
                                                    </li>
                                                    <li class="eur">
                                                        Clone Event
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div
                                            className={`umitem__content-action ${
                                                activeDropdown ===
                                                `${item._id}-4`
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                toggleDropdown(`${item._id}-4`)
                                            }
                                        >
                                            <span className="color--red">
                                                Dispose
                                            </span>
                                            <img
                                                src={ArrowDownOrangeIcon}
                                                alt=""
                                            />

                                            <div class="action__list">
                                                <ul>
                                                    <li>
                                                        <b>Dispose Event</b>
                                                    </li>
                                                    <li class="du color--red">
                                                        Cancel Event
                                                    </li>
                                                    <li class="du color--red">
                                                        Archive Event
                                                    </li>
                                                    <li class="du color--red">
                                                        Delete Event
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="page__pagination" style={{ display: "none" }}>
                <ul>
                    <li>
                        <img src={PaginationLeftIcon} alt="" />
                    </li>
                    <li className="active">
                        <span>1</span>
                    </li>
                    <li>
                        <span>2</span>
                    </li>
                    <li>
                        <span>3</span>
                    </li>
                    <li>
                        <span>...</span>
                    </li>
                    <li>
                        <span>22</span>
                    </li>
                    <li>
                        <span>23</span>
                    </li>
                    <li>
                        <span>24</span>
                    </li>
                    <li>
                        <img src={PaginationRightIcon} alt="" />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Events;
