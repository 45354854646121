import { authHeader } from "./authHeader";
import { API_URL } from "../config";

const API = `${API_URL}/users`;

const login = (data) => {
    return fetch(`${API}/login`, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (!res.ok) {
                return res.json().then((r) => {
                    throw Error(r.message);
                });
            }

            return res.json();
        })
        .then((resJson) => {
            if (resJson.token && resJson.user) {
                localStorage.setItem("token", resJson.token);
                localStorage.setItem("user", JSON.stringify(resJson.user));
            }

            return resJson;
        });
};

const register = (data) => {
    return fetch(`${API}/register`, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const update = (data) => {
    return fetch(`${API}/`, {
        method: "PUT",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
};

const getUser = (id) => {
    return fetch(`${API}/single/${id}`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => res.json());
};
const getCompany = () => {
    return fetch(`${API}/get-company`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => res.json());
};
const usersService = {
    login,
    register,
    logout,
    getUser,
    getCompany,
    update,
};

export default usersService;
