import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";

import HomeIcon from "../../assets/img/home-icon-breadcrumb.svg";
import PaymentIcon from "../../assets/img/payment-icon.svg";

import companiesService from "../../services/company.service";
import usersService from "../../services/user.service";

const CompanySettings = (props) => {
    const [company, setCompany] = useState({});

    const [companyInitialValues, setCompanyInitialValues] = useState({
        name: "",
        invoice_email: "",
        contact_person: "",
        vat: "",
        invoice_address: "",
        logo: "",
    });

    const companyValidationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required"),
        invoice_email: Yup.string().email().required("This field is required"),
        contact_person: Yup.string().required("This field is required"),
        vat: Yup.string().required("This field is required"),
        invoice_address: Yup.string().required("This field is required"),
    });

    const saveCompany = async (formValue) => {
        console.log("Saving...");
        await companiesService
            .update(company._id, formValue)
            .then((resJson) => {
                window.location.reload();
            });
    };

    useEffect(() => {
        void (async function fetchData() {
            await usersService.getCompany().then((resJson) => {
                setCompany(resJson);
                setCompanyInitialValues({
                    name: resJson.name || "",
                    invoice_email: resJson.invoice_email || "",
                    contact_person: resJson.contact_person || "",
                    vat: resJson.vat || "",
                    invoice_address: resJson.invoice_address || "",
                    logo: resJson.logo || "",
                });
            });
        })();
    }, []);
    return (
        <div className="settings">
            <div className="breadcrumb">
                <ul>
                    <li>
                        <a href="getstarted.php">
                            <img src={HomeIcon} alt="" />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">Settings</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">Suppliers</a>
                    </li>
                </ul>
            </div>
            <div className="settings__company">
                <div className="settings__company-left">
                    <Formik
                        enableReinitialize
                        initialValues={companyInitialValues}
                        validationSchema={companyValidationSchema}
                        onSubmit={saveCompany}
                    >
                        {({ values, touched, errors, handleChange }) => {
                            return (
                                <Form>
                                    <div className="settings__company-info">
                                        <h4>Company Settings</h4>
                                        <div className="custom__input">
                                            <label for="">
                                                Organization Name
                                            </label>
                                            <input
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div className="custom__input">
                                            <label for="">Invoice Email</label>
                                            <input
                                                name="invoice_email"
                                                value={values.invoice_email}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div className="custom__input">
                                            <label for="">
                                                Name Of Contact Person
                                            </label>
                                            <input
                                                name="contact_person"
                                                value={values.contact_person}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div className="custom__input">
                                            <label for="">VAT Number</label>
                                            <input
                                                name="vat"
                                                value={values.vat}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div className="custom__input">
                                            <label for="">
                                                Invoice Address
                                            </label>
                                            <textarea
                                                name="invoice_address"
                                                id=""
                                                cols="30"
                                                rows="10"
                                                value={values.invoice_address}
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                        <div className="custom__upload">
                                            <h5>Company Logo</h5>
                                            <div className="placeholder">
                                                <div className="placeholder__image">
                                                    LOGO
                                                </div>
                                            </div>
                                            <div className="custom__upload-buttons">
                                                <input
                                                    type="file"
                                                    id="company_logo"
                                                />
                                                <label for="company_logo">
                                                    Change
                                                </label>
                                                <a href="#">Delete</a>
                                            </div>
                                        </div>
                                        <div className="custom__button">
                                            <button type="submit" name="submit">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
                <div className="settings__company-right">
                    <div className="settings__company-plan">
                        <h4>Your Current Plan</h4>
                        <div className="current__plan">
                            <h3>Platinum</h3>
                            <a href="#">Change Plan</a>
                        </div>
                        <div className="plan__info">
                            <div className="plan__info-item">
                                <b>Since:</b>
                                <p>DD/MM/YYYY</p>
                            </div>
                            <div className="plan__info-item">
                                <b>Details:</b>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </p>
                            </div>
                            <div
                                className="
                                            plan__info-item plan__info-item-two
                                        "
                            >
                                <div className="plan__info-item-double">
                                    <b>Last Invoice:</b>
                                    <p>DD/MM/YYYY</p>
                                </div>
                                <div className="plan__info-item-double">
                                    <b>Next Invoice:</b>
                                    <p>DD/MM/YYYY</p>
                                </div>
                            </div>
                        </div>
                        <div className="plan__button">
                            <a href="#">Invoice History</a>
                        </div>
                    </div>
                    <div className="settings__company-payment">
                        <h4>Payment Method Information</h4>
                        <div className="custom__input">
                            <label for="">Name On Card</label>
                            <input type="text" />
                        </div>
                        <div className="custom__input">
                            <label for="">Card Number</label>
                            <input type="text" />
                        </div>
                        <div className="payment__list">
                            <h5>Expiry</h5>
                            <div className="payment__list-item">
                                <div className="custom__select">
                                    <select name="" id="">
                                        <option value="#">Month</option>
                                    </select>
                                </div>
                            </div>
                            <div className="payment__list-item">
                                <div className="custom__select">
                                    <select name="" id="">
                                        <option value="#">Month</option>
                                    </select>
                                </div>
                            </div>
                            <div className="payment__list-item">
                                <div className="custom__input">
                                    <input type="text" />
                                </div>
                            </div>
                            <div className="payment__list-item">
                                <img src={PaymentIcon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanySettings;
