import { authHeader } from "./authHeader";
import { API_URL } from "../config";

const API = `${API_URL}/invites`;

const create = async (data) => {
    return fetch(`${API}/create`, {
        method: "POST",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const update = async (id, data) => {
    return fetch(`${API}/${id}`, {
        method: "PUT",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const remove = async (id) => {
    return fetch(`${API}/${id}`, {
        method: "DELETE",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const filter = (id, filter) => {
    return fetch(`${API}/filter/${id}`, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(filter),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const all = async (id) => {
    return fetch(`${API}/all/${id}`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const allCompany = async () => {
    return fetch(`${API}/company/all`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const get = async (id) => {
    return fetch(`${API}/${id}`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const getByDateTime = async (id, date, time) => {
    return fetch(`${API}/datetime/${id}`, {
        method: "POST",
        headers: authHeader(true),
        body: JSON.stringify({
            date: date,
            time: time,
        }),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const send = async () => {
    return fetch(`${API}/send`, {
        method: "POST",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const invitesService = {
    create,
    all,
    get,
    update,
    remove,
    allCompany,
    send,
    filter,
    getByDateTime,
};

export default invitesService;
