import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";

import { useDispatch, useSelector } from "react-redux";

import qoneLogo from "../assets/img/qone-logo.svg";
import avatar from "../assets/img/avatar.svg";
import Modal from "./Modal";

import { userLogout } from "../features/userSlice";

import usersService from "../services/user.service";

const Header = (props) => {
    const { user } = useSelector((state) => state.user);
    const [showPersonal, setShowPersonal] = useState(false);

    const dispatch = useDispatch();

    const [personalInit, setPersonalInit] = useState({
        name: user.name || "",
        email: user.email || "",
        phone: user.phone || "",
    });

    const handleLogout = () => {
        dispatch(userLogout())
            .unwrap()
            .then(() => {
                props.history.push("/login");
                window.location.reload();
            })
            .catch((er) => console.log("error", er));
    };
    const updateProfile = async (formValues) => {
        await usersService.update(formValues).then((resJson) => {
            setShowPersonal(false);
            localStorage.setItem("user", JSON.stringify(resJson));
        });
    };
    return (
        <div className="app__header">
            <Modal show={showPersonal}>
                <div class="app__modal-content">
                    <div className="personal__title">
                        <h4>Personal Settings</h4>
                    </div>
                    <Formik
                        initialValues={personalInit}
                        // validationSchema={eventDateValidationSchema}
                        onSubmit={updateProfile}
                    >
                        {({ values, touched, errors, handleChange }) => {
                            return (
                                <Form>
                                    <div
                                        class="custom__input"
                                        style={{ marginTop: "10px" }}
                                    >
                                        <label for="">Your Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div
                                        class="custom__input"
                                        style={{ marginTop: "10px" }}
                                    >
                                        <label for="">Your Email</label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div
                                        class="custom__input"
                                        style={{ marginTop: "10px" }}
                                    >
                                        <label for="">Your Phone Number</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div class="app__modal-bottom">
                                        <button
                                            onClick={() =>
                                                setShowPersonal(false)
                                            }
                                            class="button-o"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" name="submit">
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
            <div className="header__logo">
                <img src={qoneLogo} alt="" />
            </div>
            <div className="header__actions">
                <div className="actions__head">
                    <img src={avatar} alt="" />
                </div>
                <div className="actions__content">
                    <ul>
                        <li>
                            <h5>{user.name}</h5>
                        </li>
                        <li onClick={() => setShowPersonal(true)}>
                            <a href="javascript:void(null)">
                                Personal Settings
                            </a>
                        </li>
                        <li>
                            <h5>Pages</h5>
                        </li>
                        <li>
                            <a href="/events">Event Management</a>
                        </li>
                        {/* <li>
                            <a href="#">Survey Designer</a>
                        </li> */}
                        <li>
                            <a href="/">Q One Platform</a>
                        </li>
                        <li>
                            <h5>Help</h5>
                        </li>
                        <li>
                            <a href="#">Tutorials</a>
                        </li>
                        <li>
                            <a href="#">FAQ</a>
                        </li>
                        <li>
                            <a href="#">Contact Support</a>
                        </li>
                        <li>
                            <h5>System Settings</h5>
                        </li>
                        <li>
                            <a href="/main-settings">Settings Home</a>
                        </li>
                        <li>
                            <a href="/user-management">User Management</a>
                        </li>
                        <li>
                            <a href="/company-settings">Company Settings</a>
                        </li>
                        <li onClick={() => handleLogout()}>
                            <a href="javascript:void(null)">Log Out</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;
