import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import homeIcon from "../assets/img/nav/home-icon.svg";
import userManagementIcon from "../assets/img/nav/user-management-icon.svg";
import companySettingsIcon from "../assets/img/nav/company-settings-icon.svg";
import legalSecurityIcon from "../assets/img/nav/legal-security-icon.svg";
import languageIcon from "../assets/img/nav/language-icon.svg";
import timezonesIcon from "../assets/img/nav/timezones-icon.svg";
import notificationsIcon from "../assets/img/nav/notifications-icon.svg";
import defaulQuestionsIcon from "../assets/img/nav/default-questions-icon.svg";
import pageDesignIcon from "../assets/img/nav/page-design-icon.svg";
import exitDestinationIcon from "../assets/img/nav/exit-destination-icon.svg";
import copyIcon from "../assets/img/copy.svg";

const Sidebar = (props) => {
    const { pathname } = useLocation();
    return (
        <div className="app__sidebar">
            <nav className="sidebar__nav">
                <ul>
                    <li>
                        <a href="/dashboard">
                            <img src={homeIcon} alt="" />
                            <span>Home</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <nav className="sidebar__nav">
                <ul>
                    <li className="sidebar__nav-head">
                        <b>Pages</b>
                    </li>
                    <li>
                        <a href="/events">
                            <img src={copyIcon} alt="" />
                            <span>Events</span>
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="https://demo.qone-tech.com/newdesign/site/qonetech/login.php"
                        >
                            <img src={companySettingsIcon} alt="" />
                            <span>Q One Platform</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
