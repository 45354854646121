import React, { useState, useEffect } from "react";

import HomeIcon from "../../assets/img/home-icon-breadcrumb.svg";
const PrivacyCentar = (props) => {
    return (
        <div className="settings">
            <div className="breadcrumb">
                <ul>
                    <li>
                        <a href="getstarted.php">
                            <img src={HomeIcon} alt="" />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">Settings</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">Suppliers</a>
                    </li>
                </ul>
            </div>
            <div className="settings__list">
                <div className="settings__list-items">
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Privacy Policy</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Last Updated</b>
                                        <span>[DD/MM/YYYY]</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#"> View </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Terms & Conditions</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Last Updated</b>
                                        <span>[DD/MM/YYYY]</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#"> View </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Data Processing Agreement</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="inline__list">
                                <div className="inline__list-item">
                                    <span>Data Processing Agreement</span>
                                    <h3>Not Signed</h3>
                                </div>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#"> Visit </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Data Retention Policy</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Last Updated</b>
                                        <span>[DD/MM/YYYY]</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#"> View </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Security Information</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Last Updated</b>
                                        <span>[DD/MM/YYYY]</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#"> View </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyCentar;
