import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./features/userSlice";
import messageReducer from "./features/messageSlice";

const reducer = {
    user: userReducer,
    message: messageReducer,
};

const store = configureStore({
    reducer: reducer,
    devTools: true,
});

export default store;
