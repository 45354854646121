import React, { useState, useEffect } from "react";

import validator from "validator";
import Moment from "react-moment";

import eventsService from "../../services/event.service";
import membersService from "../../services/member.service";

import webLogoIcon from "../../assets/img/web-logo.svg";
import confirmYourAppointmentIcon from "../../assets/img/confirm-your-appointment.svg";
import calendarIcon from "../../assets/img/calendar.svg";
import timeIcon from "../../assets/img/time.svg";
import editIcon from "../../assets/img/edit.svg";

const Web = (props) => {
    const [step, setStep] = useState(1);
    const [error, setError] = useState(false);
    const [eventTimes, setEventTimes] = useState([]);
    const [currentTimes, setCurrentTimes] = useState([]);
    const [event, setEvent] = useState({});

    const [formData, setFormData] = useState({
        event: props.match.params.id,
        first_name: "",
        last_name: "",
        language: "",
        email: "",
        repeat_email: "",
        phone_number: "",
        timezone: "",
        appointment: "",
        date: "",
        time: "",
    });

    useEffect(() => {
        void (async function fetchData() {
            await eventsService
                .getTimes(props.match.params.id)
                .then((resJson) => {
                    setEventTimes(resJson);
                });
            await eventsService.get(props.match.params.id).then((resJson) => {
                setEvent(resJson);
            });
        })();
    }, []);

    const nextStep = (validateInput) => {
        if (!validateInput) {
            setStep((s) => s + 1);
        }
        if (validateInput == "repeat_email") {
            if (formData.repeat_email !== formData.email) {
                setError(true);
                return;
            }
        }
        if (validator.isEmpty(formData[validateInput])) {
            setError(true);
        } else {
            setStep((s) => s + 1);
        }
    };
    const prevStep = () => {
        setStep((s) => s - 1);
    };

    const confirmAppointment = async () => {
        var data = formData;
        data.appointment = `${formData.date} ${formData.time}`;

        await membersService.create(data).then((resJson) => {
            if (event?.exit_page.variation == "external") {
                window.location.href = event?.exit_page.url;
                return;
            } else {
                nextStep();
            }
        });
    };
    const handleInputData = (input) => (e) => {
        const { value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [input]: value,
        }));
    };
    const setCustomValue = (input, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [input]: value,
        }));
    };

    return (
        <div className="web">
            <div className="web__content">
                <header className="header">
                    <div className="header__logo">
                        <img src={webLogoIcon} alt="" />
                    </div>
                </header>
                <div className={`web__box step-1 ${step == 1 ? "active" : ""}`}>
                    <div className="web__box-content">
                        <h4>Welcome!</h4>
                        <p>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum.
                        </p>
                        <div className="custom__select">
                            <select
                                name="language"
                                defaultValue={formData.language}
                                onChange={handleInputData("language")}
                            >
                                <option value="">Select your language</option>
                                <option value="EN">English</option>
                                <option value="BA">Bosnian</option>
                            </select>
                        </div>
                    </div>
                    <div className="web__box-button">
                        <a href="#" onClick={() => nextStep("language")}>
                            Next
                        </a>
                    </div>
                </div>
                <div className={`web__box step-2 ${step == 2 ? "active" : ""}`}>
                    <div className="web__box-content">
                        <h4>What is name?</h4>
                        <div className="custom__input">
                            <input
                                type="text"
                                placeholder="Enter your first name"
                                name="first_name"
                                defaultValue={formData.first_name}
                                onChange={handleInputData("first_name")}
                            />
                        </div>
                        <div className="custom__input">
                            <input
                                type="text"
                                placeholder="Enter your last name"
                                name="last_name"
                                defaultValue={formData.last_name}
                                onChange={handleInputData("last_name")}
                            />
                        </div>
                    </div>
                    <div className="web__box-button web__box-button--double">
                        <a
                            className="button--empty"
                            href="#"
                            onClick={() => prevStep()}
                        >
                            Back
                        </a>
                        <a href="#" onClick={() => nextStep("first_name")}>
                            Next
                        </a>
                    </div>
                </div>
                <div className={`web__box step-3 ${step == 3 ? "active" : ""}`}>
                    <div className="web__box-content">
                        <h4>What is your email?</h4>
                        <p>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua.
                        </p>
                        <div className="custom__input">
                            <input
                                type="text"
                                placeholder="Enter your email address"
                                name="email"
                                defaultValue={formData.email}
                                onChange={handleInputData("email")}
                            />
                        </div>
                    </div>
                    <div className="web__box-button web__box-button--double">
                        <a
                            className="button--empty"
                            href="#"
                            onClick={() => prevStep()}
                        >
                            Back
                        </a>
                        <a href="#" onClick={() => nextStep("email")}>
                            Next
                        </a>
                    </div>
                </div>
                <div className={`web__box step-4 ${step == 4 ? "active" : ""}`}>
                    <div className="web__box-content">
                        <h4>Please confirm your email.</h4>
                        <div className="web__box-divider"></div>
                        <div className="custom__input">
                            <input
                                type="text"
                                placeholder="Enter your email address"
                                name="repeat_email"
                                defaultValue={formData.repeat_email}
                                onChange={handleInputData("repeat_email")}
                            />
                        </div>
                    </div>
                    <div className="web__box-button web__box-button--double">
                        <a
                            className="button--empty"
                            href="#"
                            onClick={() => prevStep()}
                        >
                            Back
                        </a>
                        <a href="#" onClick={() => nextStep("repeat_email")}>
                            Next
                        </a>
                    </div>
                </div>
                <div className={`web__box step-5 ${step == 5 ? "active" : ""}`}>
                    <div className="web__box-content">
                        <h4>What is your mobile number?</h4>
                        <div className="web__box-divider"></div>
                        <div className="custom__input">
                            <input
                                type="text"
                                placeholder="Enter your mobile number"
                                name="phone_number"
                                defaultValue={formData.phone_number}
                                onChange={handleInputData("phone_number")}
                            />
                        </div>
                    </div>
                    <div className="web__box-button web__box-button--double">
                        <a
                            className="button--empty"
                            href="#"
                            onClick={() => prevStep()}
                        >
                            Back
                        </a>
                        <a href="#" onClick={() => nextStep("phone_number")}>
                            Next
                        </a>
                    </div>
                </div>
                <div className={`web__box step-6 ${step == 6 ? "active" : ""}`}>
                    <div className="web__box-content">
                        <h4>What is your time zone?</h4>
                        <div className="web__box-divider"></div>
                        <div className="custom__select">
                            <select
                                name="timezone"
                                defaultValue={formData.timezone}
                                onChange={handleInputData("timezone")}
                                id=""
                            >
                                <option value="TZ">Time zone</option>
                                <option value="NTZ">Normal TIMEZONE</option>
                            </select>
                        </div>
                    </div>
                    <div className="web__box-button web__box-button--double">
                        <a
                            className="button--empty"
                            href="#"
                            onClick={() => prevStep()}
                        >
                            Back
                        </a>
                        <a href="#" onClick={() => nextStep("timezone")}>
                            Next
                        </a>
                    </div>
                </div>
                <div
                    className={`web__from step-7 ${step == 7 ? "active" : ""}`}
                >
                    <div className="web__box">
                        <div className="web__box-content">
                            <h5>Please select a date</h5>
                            <div className="date__list">
                                {/* <div className="date__placeholder">
                                    <img
                                        src="../img/arrow-left-orange.svg"
                                        alt=""
                                    />
                                    <span>[MONTH] [YYYY]</span>
                                    <img
                                        src="../img/arrow-right-orange.svg"
                                        alt=""
                                    />
                                </div> */}
                                <div className="date__items">
                                    {eventTimes.map((item, index) => {
                                        let newDate = item.date.split("/");
                                        console.log(newDate);
                                        let theDate = new Date(
                                            newDate[2],
                                            newDate[1] - 1,
                                            newDate[0]
                                        );
                                        console.log(theDate);
                                        return (
                                            <div
                                                className="date__item"
                                                onClick={() => {
                                                    setCustomValue(
                                                        "date",
                                                        item.date
                                                    );
                                                    setCurrentTimes(item.times);
                                                }}
                                            >
                                                <span>
                                                    <Moment format="ddd">
                                                        {theDate}
                                                    </Moment>
                                                </span>
                                                <h5>
                                                    <Moment format="D">
                                                        {theDate}
                                                    </Moment>
                                                </h5>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {formData.date && (
                                <>
                                    <h5 className="rotate">
                                        Please select a time
                                    </h5>
                                    <div className="time__list">
                                        {currentTimes.map((item, index) => {
                                            return (
                                                <div
                                                    onClick={() =>
                                                        setCustomValue(
                                                            "time",
                                                            `${item.hour}:${item.minute}`
                                                        )
                                                    }
                                                    className="time__item"
                                                >
                                                    {item.hour}:{item.minute}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {formData.time && (
                        <div class="extra__box">
                            <h5>Confirm Your Appointment</h5>
                            <img
                                class="cappo"
                                src={confirmYourAppointmentIcon}
                                alt=""
                            />
                            <div class="extra__box-info-list">
                                <ul>
                                    <li>
                                        <div class="info__list-top">
                                            <img src={calendarIcon} alt="" />
                                            <span>Date</span>
                                        </div>
                                        <p>{formData.date}</p>
                                    </li>
                                    <li>
                                        <div class="info__list-top">
                                            <img src={timeIcon} alt="" />
                                            <span>Time</span>
                                        </div>
                                        <p>{formData.time}</p>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <div class="info__list-top">
                                            <span>Your Time Zone</span>
                                        </div>
                                        <p>{formData.timezone}</p>

                                        <div class="item-action">
                                            <img src={editIcon} alt="" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="info__list-top">
                                            <span>Language</span>
                                        </div>
                                        <p>{formData.language}</p>

                                        <div class="item-action">
                                            <img src={editIcon} alt="" />
                                        </div>
                                    </li>
                                </ul>
                                <div class="info__list-contact">
                                    <h6>How may we contact you?</h6>

                                    <div class="info__list-contact-item">
                                        <div class="custom__switch">
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div class="list-contact-item-content">
                                            <b>By Email to:</b>
                                            <span>{formData.email}</span>
                                            <div class="item-action">
                                                <img src={editIcon} alt="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="info__list-contact-item">
                                        <div class="custom__switch">
                                            <label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div class="list-contact-item-content">
                                            <b>By SMS to:</b>
                                            <span>{formData.phone_number}</span>
                                            <div class="item-action">
                                                <img src={editIcon} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info__list-button">
                                    <button
                                        onClick={() => confirmAppointment()}
                                    >
                                        Confirm Appointment
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    class={`web__box web__box--sp step-8 ${
                        step == 8 ? "active" : ""
                    }`}
                >
                    <div class="web__box-content">
                        <h4>Thank you for confirming your appointment!</h4>
                        <p>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Web;
