import React, { useState, useEffect } from "react";

const MainSettingsPage = (props) => {
    return (
        <div className="settings">
            <div className="settings__list">
                <div className="settings__list-header">
                    <h4>Company Settings</h4>
                </div>
                <div className="settings__list-items">
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>User Management</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="inline__list">
                                <div className="inline__list-item">
                                    <h5>4</h5>
                                    <span>Active Users</span>
                                </div>
                                <div className="inline__list-item">
                                    <h5>0</h5>
                                    <span>Inactive Accounts</span>
                                </div>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="/user-management"> Edit </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Company Settings</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Organization:</b>
                                        <span>[COMPANY NAME]</span>
                                    </li>
                                    <li>
                                        <b>Your Plan:</b>
                                        <span>[PLAN NAME]</span>
                                    </li>
                                    <li>
                                        <b>Invoice Email:</b>
                                        <span>[INVOICE EMAIL]</span>
                                    </li>
                                    <li>
                                        <b>Last Invoice:</b>
                                        <span>[DD/MM/YYYY]</span>
                                    </li>
                                    <li>
                                        <b>Next Invoice</b>
                                        <span>[DD/MM/YYYY]</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="/company-settings"> Go </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>User Management</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="inline__list">
                                <div className="inline__list-item">
                                    <span>Inactive Accounts</span>
                                    <h3>Not Signed</h3>
                                </div>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="/privacy-centar"> Visit </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings__list">
                <div className="settings__list-header">
                    <h4>System Default Settings</h4>
                </div>
                <div className="settings__list-items">
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Languages</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="inline__list">
                                <div className="inline__list-item">
                                    <h5>8</h5>
                                    <span>Languages Enabled</span>
                                </div>
                                <div className="inline__list-item">
                                    <h5>English</h5>
                                    <span>Your Default Language</span>
                                </div>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="/translations"> Go </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Time Zones</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="inline__list">
                                <div className="inline__list-item">
                                    <h5>8</h5>
                                    <span>Timezones Enabled</span>
                                </div>
                                <div className="inline__list-item">
                                    <h5>EST</h5>
                                    <span>Your Default Timezone</span>
                                </div>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#"> Go </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Notifications</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="/event-notifications"> Go </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Default Questions</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="inline__list">
                                <div className="inline__list-item">
                                    <h5>5</h5>
                                    <span>Default Questions</span>
                                </div>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="/default-questions"> Go </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Page Design</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#"> Go </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Exit Destination</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#"> Go </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainSettingsPage;
