import React, { useState, useEffect } from "react";

import SettingsGrayIcon from "../../assets/img/settings-gray.svg";
import ArrowDownGrayIcon from "../../assets/img/arrow-down-gray.svg";
import CopyIcon from "../../assets/img/copy.svg";
import TrashIcon from "../../assets/img/trash.svg";
const EventTimesBox = (props) => {
    return (
        <div className="event__times-box">
            <div className="event__times-box-top">
                <b>{props.date}</b>
                <div className="et__options">
                    <span>
                        <img src={SettingsGrayIcon} alt="" />
                        Options
                        <img src={ArrowDownGrayIcon} alt="" />
                    </span>

                    <div className="et__options-list">
                        <ul>
                            <li onClick={props.onChangeDate}>Change Date</li>
                            <li>Copy times to all dates</li>
                            <li onClick={props.onCloneSetup}>Clone Set Up</li>
                            <li
                                onClick={props.onDeleteSetup}
                                className="color--red"
                            >
                                Delete Set Up
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="event__times-box-items">{props.children}</div>
        </div>
    );
};

export default EventTimesBox;
