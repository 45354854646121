import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";

import HomeIcon from "../../assets/img/home-icon-breadcrumb.svg";
import SearchWhiteIcon from "../../assets/img/search-white.svg";
import WheelIcon from "../../assets/img/wheel.svg";
import FullArrowOrangeDownIcon from "../../assets/img/full-arrow-orange-down.svg";
import ArrowDownOrangeIcon from "../../assets/img/arrow-down-orange.svg";
import CloseWhiteIcon from "../../assets/img/close-white.svg";

import Modal from "../../common/Modal";

import invitesService from "../../services/invite.service";

const UserManagement = (props) => {
    const [invites, setInvites] = useState([]);
    const [inviteNew, setInviteNew] = useState(false);

    const [inviteValues, setInviteValues] = useState({
        name: "",
        email: "",
        role: "administrator",
    });

    useEffect(() => {
        void (async function fetchData() {
            await invitesService.allCompany().then((resJson) => {
                setInvites(resJson);
            });
        })();
    }, []);

    const sendInvite = (formValues) => {
        console.log("formvvalues", formValues);
    };

    return (
        <div className="settings">
            <Modal show={inviteNew}>
                <div class="app__modal-box">
                    <div class="app__modal-title">
                        <h4>Invite New User</h4>
                        <img class="modal--close" src={CloseWhiteIcon} alt="" />
                    </div>
                    <Formik initialValues={inviteValues} onSubmit={sendInvite}>
                        {({ values, errors, touched, handleChange }) => {
                            <Form>
                                <div class="app__modal-content">
                                    <div class="app__modal-big-input">
                                        <label for="">Name</label>
                                        <input name="name" type="text" />
                                    </div>
                                    <div class="app__modal-big-input">
                                        <label for="">Email</label>
                                        <input name="email" type="text" />
                                    </div>
                                    <div class="app__modal-big-select">
                                        <label for="">Role</label>
                                        <div class="custom__select">
                                            <select name="role" id="">
                                                <option value="administrator">
                                                    Administrator
                                                </option>
                                                <option value="manager">
                                                    Manager
                                                </option>
                                                <option value="observer">
                                                    Observer
                                                </option>
                                                <option value="translator">
                                                    Translator
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="app__modal-bottom">
                                        <button
                                            onClick={() => setInviteNew(false)}
                                            class="button-o"
                                            type="button"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" name="submit">
                                            Send Invite
                                        </button>
                                    </div>
                                </div>
                            </Form>;
                        }}
                    </Formik>
                </div>
            </Modal>
            <div className="settings__um-top">
                <div className="breadcrumb">
                    <ul>
                        <li>
                            <a href="getstarted.php">
                                <img src={HomeIcon} alt="" />
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Settings</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">User Management</a>
                        </li>
                    </ul>
                </div>
                <div className="settings__um-top-actions">
                    <a className="inu" href="#">
                        {" "}
                        + Invite New User{" "}
                    </a>
                    <form action="#">
                        <input type="text" placeholder="Enter search term" />
                        <button>
                            <img src={SearchWhiteIcon} alt="" />
                        </button>
                    </form>
                </div>
            </div>
            <div className="settings__um-options">
                <div className="custom__checkbox">
                    <label className="custom__checkbox-container">
                        <input type="checkbox" checked="checked" />
                        <span className="checkmark"></span>
                    </label>
                    <h5>Select All</h5>
                </div>
                <div className="custom__option">
                    <img src={WheelIcon} alt="" />
                    <span>Bulk Actions</span>
                    <img src={FullArrowOrangeDownIcon} alt="" />
                </div>
            </div>
            <div className="settings__um-list">
                {invites.map((item, index) => {
                    return (
                        <div className="settings__um-out">
                            <div className="settings__um-item">
                                <div className="umitem__top">
                                    <em className="color--green">Online</em>
                                    <h5>{item.name}</h5>
                                    <div className="custom__checkbox">
                                        <label className="custom__checkbox-container">
                                            <input
                                                type="checkbox"
                                                checked="checked"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="umitem__content">
                                    <div className="umitem__content-left">
                                        <ul>
                                            <li>
                                                <span>Role:</span>
                                                <em>{item.role}</em>
                                            </li>
                                            <li>
                                                <span>Last Log In:</span>
                                                <em>-:-</em>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="umitem__content-right">
                                        <div className="umitem__content-action">
                                            <span>Enable</span>
                                            <div className="custom__switch">
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="umitem__content-action">
                                            <span>Actions</span>
                                            <img
                                                src={ArrowDownOrangeIcon}
                                                alt=""
                                            />

                                            <div className="action__list">
                                                <ul>
                                                    <li>
                                                        <b>User Actions</b>
                                                    </li>
                                                    <li className="eup">
                                                        Edit User Permissions
                                                    </li>
                                                    <li className="eur">
                                                        Edit User Role
                                                    </li>
                                                    <li className="rup">
                                                        Reset User Password
                                                    </li>
                                                    <li className="du color--red">
                                                        Delete User
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default UserManagement;
