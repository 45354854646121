import React, { useState, useEffect } from "react";

import HomeIcon from "../../assets/img/home-icon-breadcrumb.svg";
import ArrowOIcon from "../../assets/img/arrow-o.svg";

const DefaultQuestions = (props) => {
    return (
        <div className="settings">
            <div className="settings__questions">
                <div className="questions__top">
                    <div className="breadcrumb">
                        <ul>
                            <li>
                                <a href="getstarted.php">
                                    <img src={HomeIcon} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Settings</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Questions</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="questions__block">
                    <div className="questions__box">
                        <h4>Company Settings</h4>
                        <div className="custom__question-input">
                            <div className="question__arrows">
                                <img src={ArrowOIcon} alt="" />
                            </div>
                            <div className="custom__input">
                                <label for="">Your Name</label>
                                <input type="text" />
                            </div>
                            <div className="question__actions">
                                <a href="#">Edit</a>
                                <a href="#">Delete</a>
                            </div>
                        </div>
                        <div className="custom__question-input">
                            <div className="question__arrows">
                                <img src={ArrowOIcon} alt="" />
                            </div>
                            <div className="custom__input">
                                <label for="">Your Email</label>
                                <input type="text" />
                            </div>
                            <div className="question__actions">
                                <a href="#">Edit</a>
                                <a href="#">Delete</a>
                            </div>
                        </div>
                        <div className="custom__question-input">
                            <div className="question__arrows">
                                <img src={ArrowOIcon} alt="" />
                            </div>
                            <div className="custom__input">
                                <label for="">
                                    Please add anything you want to share with
                                    us before our meeting.
                                </label>
                                <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="10"
                                ></textarea>
                            </div>
                            <div className="question__actions">
                                <a href="#">Edit</a>
                                <a href="#">Delete</a>
                            </div>
                        </div>
                        <div className="custom__question-button">
                            <a href="#"> + Add New Question Here </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DefaultQuestions;
