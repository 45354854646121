import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const NonAuthRoute = ({ component: Component, pass, ...rest }) => {
    const { isLoggedIn } = useSelector((state) => state.user);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isLoggedIn || pass) {
                    return <Component {...rest} {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/dashboard",
                            }}
                        />
                    );
                }
            }}
        />
    );
};

export default NonAuthRoute;
