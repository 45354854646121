import React, { useState, useEffect } from "react";

import HomeIcon from "../../assets/img/home-icon-breadcrumb.svg";
import PlayWhiteIcon from "../../assets/img/play-white.svg";
import SettingsWhiteIcon from "../../assets/img/settings-white.svg";
import EditOrangeIcon from "../../assets/img/edit-orange.svg";
import searchIcon from "../../assets/img/search-white.svg";

import Modal from "../../common/Modal";

import { Formik, Form, Field } from "formik";

import membersService from "../../services/member.service";
import eventsService from "../../services/event.service";
import usersService from "../../services/user.service";
import invitesService from "../../services/invite.service";

import { EVENT_URL } from "../../config";

const ProjectManagement = (props) => {
    const [members, setMembers] = useState([]);
    const [observers, setObservers] = useState([]);
    const [pmType, setPmType] = useState("member");

    const [selectedMember, setSelectedMember] = useState({});
    const [selectedObserver, setSelectedObserver] = useState({});

    const [modal, setModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [event, setEvent] = useState({});
    const [company, setCompany] = useState({});
    const [events, setEvents] = useState([]);
    const [filter, setFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        event: "all",
        language: "all",
        status: "all",
        sort: "asc",
    });
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectedObservers, setSelectedObservers] = useState([]);
    const [editable, setEditable] = useState([]);

    const [initialValues, setInitialValues] = useState({
        first_name: "",
        last_name: "",
        status: 0,
        email: "",
        phone_number: "",
        appointment: "",
        language: "",
        timezone: "",
    });
    const [observerInitialValues, setObserverInitialValues] = useState({
        name: "",
        email: "",
        phone_number: "",
        appointment: "",
        language: "",
        timezone: "",
    });
    const [addInitialValues, setAddInitialValues] = useState({
        first_name: "",
        last_name: "",
        status: 0,
        email: "",
        phone_number: "",
        appointment: "",
        language: "",
        timezone: "",
    });
    const [addObserverInitialValues, setAddObserverInitialValues] = useState({
        first_name: "",
        last_name: "",
        status: 0,
        email: "",
        phone_number: "",
        appointment: "",
        language: "",
        timezone: "",
    });

    useEffect(() => {
        void (async function fetchData() {
            await usersService.getCompany().then((resJson) => {
                setCompany(resJson);
            });
            await membersService.all(props.match.params.id).then((resJson) => {
                setMembers(resJson);
            });
            await invitesService.all(props.match.params.id).then((resJson) => {
                setObservers(resJson);
            });
            await eventsService.get(props.match.params.id).then((resJson) => {
                setEvent(resJson);
            });
            await eventsService.all(company._id).then((resJson) => {
                setEvents(resJson);
            });
        })();
    }, []);

    const copyMeetingLink = async (id, memberId) => {
        let link = `https://qual.q1platform.com/web/video/${id}/${memberId}`;
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(link);
        } else {
            return document.execCommand("copy", true, link);
        }
        alert("Link copied");
    };
    useEffect(() => {
        const filter = async (filterData) => {
            if (pmType == "member") {
                await membersService
                    .filter(props.match.params.id, filterData)
                    .then((resJson) => {
                        setMembers(resJson);
                    });
            } else {
                await invitesService
                    .filter(props.match.params.id, filterData)
                    .then((resJson) => {
                        setObservers(resJson);
                    });
            }
        };
        filter(filterData);
    }, [filterData, props.match.params.id]);

    // useEffect(() => {
    //     console.log("sm", selectedMembers);
    // }, [selectedMembers]);
    const updateSort = async (val) => {
        await membersService
            .bulkUpdate(selectedMembers, val)
            .then((resJson) => {
                console.log(resJson);
            });
    };

    const updateMember = async (formValues) => {
        await membersService
            .update(selectedMember._id, formValues)
            .then((resJson) => {
                console.log(resJson);
                setModal(false);
                window.location.reload();
            });
    };

    const updateObserver = async (formValues) => {
        await invitesService
            .update(selectedObserver._id, formValues)
            .then((resJson) => {
                setModal(false);
                window.location.reload();
            });
    };

    const addMember = async (formValues, { resetForm }) => {
        formValues.event = props.match.params.id;
        await membersService.create(formValues).then((resJson) => {
            setAddModal(false);
            resetForm();
            window.location.reload();
        });
    };

    const addObserver = async (formValues, { resetForm }) => {
        formValues.event = props.match.params.id;
        formValues.role = "observer";
        await invitesService.create(formValues).then((resJson) => {
            setAddModal(false);
            resetForm();
            window.location.reload();
        });
    };

    const enableEdit = (ind) => {
        setEditable(ind);
    };

    const convertStatus = (number) => {
        var statuses = ["Booked", "Open", "Cancel", "Completed", "No show"];
        return statuses[number];
    };
    return (
        <div style={filter ? { paddingRight: "350px" } : {}}>
            <Modal show={modal}>
                <div className="app__modal-box">
                    <Formik
                        initialValues={
                            pmType === "member"
                                ? initialValues
                                : observerInitialValues
                        }
                        onSubmit={
                            pmType === "member" ? updateMember : updateObserver
                        }
                        enableReinitialize
                    >
                        {({ values, touched, handleChange }) => (
                            <Form>
                                <div className="app__modal-title">
                                    <h4>
                                        Edit{" "}
                                        {pmType === "member"
                                            ? "Member"
                                            : "Observer"}
                                    </h4>
                                    <img
                                        className="modal--close"
                                        src="../img/close-white.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="app__modal-content">
                                    <div className="ea__list">
                                        <ul>
                                            <li
                                                className={`${
                                                    editable == 1
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() => enableEdit(1)}
                                            >
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>First Name:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <span>
                                                        {values.first_name}
                                                    </span>
                                                    <input
                                                        name="first_name"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                            <li
                                                className={`${
                                                    editable == 2
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() => enableEdit(2)}
                                            >
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>Last Name:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <span>
                                                        {values.last_name}
                                                    </span>
                                                    <input
                                                        name="last_name"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                            <li
                                                className={`${
                                                    editable == 3
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() => enableEdit(3)}
                                            >
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>Email:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <span>{values.email}</span>
                                                    <input
                                                        name="email"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                            <li
                                                className={`${
                                                    editable == 4
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() => enableEdit(4)}
                                            >
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>Mobile Number:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <span>
                                                        {values.phone_number}
                                                    </span>
                                                    <input
                                                        name="phone_number"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                            <li
                                                className={`${
                                                    editable == 5
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() => setEditable(5)}
                                            >
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>Appointment:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <span>
                                                        {values.appointment}
                                                        (Default Time Zone)
                                                    </span>
                                                    <span>
                                                        {values.appointment}
                                                        (Local Time Zone)
                                                    </span>
                                                    <input
                                                        type="datetime-local"
                                                        name="appointment"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ea__selects">
                                        {pmType === "member" && (
                                            <div className="ea__select">
                                                <label for="">Status</label>
                                                <Field
                                                    as="select"
                                                    name="status"
                                                    defaultValue={values.status}
                                                    id=""
                                                >
                                                    <option value="0">
                                                        Booked
                                                    </option>
                                                    <option value="1">
                                                        Open
                                                    </option>
                                                    <option value="2">
                                                        Cancel
                                                    </option>
                                                    <option value="3">
                                                        Completed
                                                    </option>
                                                    <option value="4">
                                                        No show
                                                    </option>
                                                </Field>
                                            </div>
                                        )}
                                        <div className="ea__select">
                                            <label for="">
                                                Interview Language
                                            </label>
                                            <Field
                                                as="select"
                                                name="language"
                                                defaultvalue="BA"
                                                id=""
                                            >
                                                <option value="">
                                                    Select language
                                                </option>
                                                <option value="BA">
                                                    Bosnia
                                                </option>
                                                <option value="EN">
                                                    English
                                                </option>
                                            </Field>
                                        </div>
                                        <div className="ea__select">
                                            <label for="">
                                                Interview Time Zone
                                            </label>
                                            <Field
                                                as="select"
                                                name="timezone"
                                                defaultvalue="test"
                                                id=""
                                            >
                                                <option value="">
                                                    Select timezone
                                                </option>
                                                <option value="test">
                                                    Test timezone
                                                </option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="app__modal-bottom">
                                        <button
                                            onClick={() =>
                                                copyMeetingLink(
                                                    props.match.params.id,
                                                    selectedMember._id
                                                )
                                            }
                                            className="button__fl"
                                        >
                                            Copy meeting link
                                        </button>
                                        <button
                                            onClick={() => setModal(false)}
                                            className="button-o"
                                            type="button"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit">Save</button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Modal show={addModal}>
                <div className="app__modal-box">
                    <Formik
                        initialValues={
                            pmType === "member"
                                ? addInitialValues
                                : addObserverInitialValues
                        }
                        onSubmit={pmType === "member" ? addMember : addObserver}
                        enableReinitialize
                    >
                        {({ values, touched, handleChange }) => (
                            <Form>
                                <div className="app__modal-title">
                                    <h4>
                                        Add{" "}
                                        {pmType === "member"
                                            ? "Member"
                                            : "Observer"}
                                    </h4>
                                    <img
                                        className="modal--close"
                                        src="../img/close-white.svg"
                                        alt=""
                                    />
                                </div>
                                <div className="app__modal-content">
                                    <div className="ea__list">
                                        <ul>
                                            <li className="active">
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>First Name:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <span>
                                                        {values.first_name}
                                                    </span>
                                                    <input
                                                        name="first_name"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                            <li className="active">
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>Last Name:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <span>
                                                        {values.last_name}
                                                    </span>
                                                    <input
                                                        name="last_name"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                            <li className="active">
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>Email:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <span>{values.email}</span>
                                                    <input
                                                        name="email"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                            <li className="active">
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>Mobile Number:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <span>
                                                        {values.phone_number}
                                                    </span>
                                                    <input
                                                        name="phone_number"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                            <li className="active">
                                                <div className="ea__list-item-left">
                                                    <img
                                                        src={EditOrangeIcon}
                                                        alt=""
                                                    />
                                                    <b>Appointment:</b>
                                                </div>
                                                <div className="ea__list-item-right">
                                                    <input
                                                        type="datetime-local"
                                                        name="appointment"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ea__selects">
                                        {pmType === "member" && (
                                            <div className="ea__select">
                                                <label for="">Status</label>
                                                <Field
                                                    as="select"
                                                    name="status"
                                                    defaultValue="0"
                                                    id=""
                                                    required
                                                >
                                                    {" "}
                                                    <option value="">
                                                        Select status
                                                    </option>
                                                    <option value="0">
                                                        Booked
                                                    </option>
                                                    <option value="1">
                                                        Open
                                                    </option>
                                                    <option value="2">
                                                        Cancel
                                                    </option>
                                                    <option value="3">
                                                        Completed
                                                    </option>
                                                    <option value="4">
                                                        No show
                                                    </option>
                                                </Field>
                                            </div>
                                        )}
                                        <div className="ea__select">
                                            <label for="">
                                                Interview Language
                                            </label>
                                            <Field
                                                as="select"
                                                name="language"
                                                defaultvalue="BA"
                                                id=""
                                            >
                                                <option value="">
                                                    Select language
                                                </option>
                                                <option value="BA">
                                                    Bosnia
                                                </option>
                                                <option value="EN">
                                                    English
                                                </option>
                                            </Field>
                                        </div>
                                        <div className="ea__select">
                                            <label for="">
                                                Interview Time Zone
                                            </label>
                                            <Field
                                                as="select"
                                                name="timezone"
                                                defaultValue="test"
                                                id=""
                                            >
                                                {" "}
                                                <option value="">
                                                    Select timezone
                                                </option>
                                                <option value="test">
                                                    Test timezone
                                                </option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="app__modal-bottom">
                                        <button
                                            onClick={() => setAddModal(false)}
                                            className="button-o"
                                            type="button"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit">Add</button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <div className="settings event__settings-tab">
                <div className="event__settings-tab-top">
                    <div className="breadcrumb">
                        <ul>
                            <li>
                                <a href="/">
                                    <img src={HomeIcon} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href={`/event/${event?._id}`}>
                                    {event?.name}
                                </a>
                            </li>
                            <li>
                                <a href={`/project-management/${event?._id}`}>
                                    Project Management
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="event__settings-buttons">
                        <div className="custom__select">
                            <select name="" id="">
                                <option value="">View Event Page</option>
                            </select>
                            <div
                                onClick={() => {
                                    window
                                        .open(
                                            `${EVENT_URL}/${props.match.params.id}`,
                                            "_blank"
                                        )
                                        .focus();
                                }}
                                className="custom__select-button"
                            >
                                <img src={PlayWhiteIcon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="event__settings-tab-top">
                    <div className="breadcrumb"></div>
                    <div className="event__settings-buttons">
                        <button>Download Results</button>
                        <button
                            onClick={() => {
                                setAddModal(true);
                            }}
                        >
                            Add Manually
                        </button>
                        <button onClick={() => setFilter(!filter)}>
                            {filter && "Hide "} Filter & Sort
                        </button>
                        <a href={`/event/${event?._id}`}>
                            <button className="button__small">
                                <img src={SettingsWhiteIcon} alt="" />
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="project__tabs">
                <div
                    className={`project__tab ${
                        pmType === "member" ? "active" : ""
                    }`}
                    onClick={() => {
                        setPmType("member");
                    }}
                >
                    Members
                </div>
                <div
                    className={`project__tab ${
                        pmType === "observer" ? "active" : ""
                    }`}
                    onClick={() => {
                        setPmType("observer");
                    }}
                >
                    Observers
                </div>
            </div>
            <div
                className={`project__appointment pma ${
                    pmType === "member" ? "active" : ""
                }`}
            >
                <div className="pa__table">
                    <div className="pa__table-row">
                        <div className="pa__medium">
                            <h5>Member ID/Status</h5>
                        </div>
                        <div className="pa__big">
                            <h5>Email/Mobile Number</h5>
                        </div>
                        <div className="pa__big">
                            <h5>First Name/Last Name</h5>
                        </div>
                        <div className="pa__big">
                            <h5>Your Time/Local Time</h5>
                        </div>
                        <div className="pa__medium">
                            <h5>Language</h5>
                        </div>
                        <div className="pa__small">
                            <h5>Edit</h5>
                        </div>
                        <div className="pa__small">
                            <h5>Select</h5>
                        </div>
                    </div>
                    {members.map((item, index) => {
                        return (
                            <div className="pa__table-row">
                                <div className="pa__medium">
                                    <h4>{item._id}</h4>
                                    <h4>
                                        Status: {convertStatus(item.status)}
                                    </h4>
                                </div>
                                <div className="pa__big">
                                    <h4>{item.email}</h4>
                                    <h4>{item.phone_number}</h4>
                                </div>
                                <div className="pa__big">
                                    <h4>{item.first_name}</h4>
                                    <h4>{item.last_name}</h4>
                                </div>
                                <div className="pa__big">
                                    <h4>{item.appointment}</h4>
                                    <h4>DD/MM/YYYY HH:MM</h4>
                                </div>
                                <div className="pa__medium">
                                    <h4>{item.language}</h4>
                                </div>
                                <div className="pa__small">
                                    <button
                                        onClick={() => {
                                            setInitialValues(item);
                                            setSelectedMember(item);
                                            setModal(true);
                                        }}
                                        className="ea"
                                    >
                                        Edit
                                    </button>
                                </div>
                                <div className="pa__small">
                                    <div className="custom__checkbox">
                                        <label className="custom__checkbox-container">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedMembers(
                                                            (prevMembers) => [
                                                                ...prevMembers,
                                                                item._id,
                                                            ]
                                                        );
                                                    } else {
                                                        setSelectedMembers(
                                                            (prevMembers) =>
                                                                prevMembers.filter(
                                                                    (pm) =>
                                                                        pm !==
                                                                        item._id
                                                                )
                                                        );
                                                    }
                                                }}
                                                // checked="checked"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div
                className={`project__appointment pma ${
                    pmType === "observer" ? "active" : ""
                }`}
            >
                <div className="pa__table">
                    <div className="pa__table-row">
                        <div className="pa__big">
                            <h5>Email</h5>
                        </div>
                        <div className="pa__big">
                            <h5>Name</h5>
                        </div>
                        <div className="pa__big">
                            <h5>Your Time/Local Time</h5>
                        </div>
                        <div className="pa__medium">
                            <h5>Language</h5>
                        </div>
                        <div className="pa__small">
                            <h5>Edit</h5>
                        </div>
                        <div className="pa__small">
                            <h5>Select</h5>
                        </div>
                    </div>
                    {observers.map((item, index) => {
                        return (
                            <div className="pa__table-row">
                                <div className="pa__big">
                                    <h4>{item.email}</h4>
                                </div>
                                <div className="pa__big">
                                    <h4>{item.name}</h4>
                                </div>
                                <div className="pa__big">
                                    <h4>{item.appointment}</h4>
                                    <h4>DD/MM/YYYY HH:MM</h4>
                                </div>
                                <div className="pa__medium">
                                    <h4>{item.language}</h4>
                                </div>
                                <div className="pa__small">
                                    <button
                                        onClick={() => {
                                            setObserverInitialValues(item);
                                            setSelectedObserver(item);
                                            setModal(true);
                                        }}
                                        className="ea"
                                    >
                                        Edit
                                    </button>
                                </div>
                                <div className="pa__small">
                                    <div className="custom__checkbox">
                                        <label className="custom__checkbox-container">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedObservers(
                                                            (prevO) => [
                                                                ...prevO,
                                                                item._id,
                                                            ]
                                                        );
                                                    } else {
                                                        setSelectedObservers(
                                                            (prevO) =>
                                                                prevO.filter(
                                                                    (pm) =>
                                                                        pm !==
                                                                        item._id
                                                                )
                                                        );
                                                    }
                                                }}
                                                // checked="checked"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div
                className={`app__right ${!filter ? "app__right--hidden" : ""}`}
            >
                <div className="app__right-content">
                    <div className="app__right-box app__right-box-pam">
                        <h4>Filter &amp; Sort</h4>
                        <div className="custom__select">
                            <select
                                onChange={(e) => {
                                    setFilterData((fd) => ({
                                        ...fd,
                                        event: e.target.value,
                                    }));
                                }}
                                name=""
                                id=""
                            >
                                <option value="all">All Events</option>
                                {events.map((item, index) => {
                                    return (
                                        <option value={item._id}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="custom__select">
                            <select
                                onChange={(e) => {
                                    setFilterData((fd) => ({
                                        ...fd,
                                        language: e.target.value,
                                    }));
                                }}
                                name=""
                                id=""
                            >
                                <option value="all">All Languages</option>
                                <option value="BA">Bosnian</option>
                                <option value="EN">English</option>
                            </select>
                        </div>
                        {pmType === "member" && (
                            <div className="custom__select">
                                <select
                                    onChange={(e) => {
                                        setFilterData((fd) => ({
                                            ...fd,
                                            status: e.target.value,
                                        }));
                                    }}
                                    name=""
                                    id=""
                                >
                                    <option value="all">All Statuses</option>
                                    <option value="0">Booked</option>
                                    <option value="1">Open</option>
                                    <option value="2">Cancel</option>
                                    <option value="3">Completed</option>
                                    <option value="4">No show</option>
                                </select>
                            </div>
                        )}
                        {pmType === "member" && (
                            <div className="custom__search">
                                <input
                                    type="text"
                                    placeholder="Enter search term"
                                />
                                <div className="cs__img">
                                    <img src={searchIcon} alt="" />
                                </div>
                            </div>
                        )}
                        {pmType === "member" && (
                            <div className="custom__select">
                                <select
                                    onChange={(e) => {
                                        setFilterData((fd) => ({
                                            ...fd,
                                            sort: e.target.value,
                                        }));
                                    }}
                                    name=""
                                    id=""
                                >
                                    <option value="desc">
                                        Sort Appointment Descending
                                    </option>
                                    <option value="asc">
                                        Sort Appointment Ascending
                                    </option>
                                </select>
                            </div>
                        )}
                        <h4>Send Reminders To Selected</h4>
                        <div className="app__right-box-button">
                            <button className="se">Send Email</button>
                            <button className="ss">Send SMS</button>
                        </div>
                        {pmType === "member" && (
                            <h4>Change Status Of Selected To:</h4>
                        )}
                        {pmType === "member" && (
                            <div className="custom__select">
                                <select
                                    onChange={(e) => updateSort(e.target.value)}
                                    name=""
                                    id=""
                                >
                                    <option value="">Select status</option>
                                    <option value="0">Booked</option>
                                    <option value="1">Open</option>
                                    <option value="2">Cancel</option>
                                    <option value="3">Completed</option>
                                    <option value="4">No show</option>
                                </select>
                            </div>
                        )}

                        <div className="app__right-box-delete">
                            <button>Delete Selected Entries</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectManagement;
