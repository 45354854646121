import React, { useState, useEffect } from "react";

const Modal = (props) => {
    return (
        <div className={`app__modal app__modal-ce ${props.show && "active"}`}>
            <div className="app__modal-box">{props.children}</div>
        </div>
    );
};

export default Modal;
