import React, { useEffect } from "react";
import "./app.css";

import { useSelector } from "react-redux";
import {
    useLocation,
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import NonAuthRoute from "./common/NonAuthRoute";
import ProtectedRoute from "./common/ProtectedRoute";

import Login from "./pages/auth/login";

import Header from "./common/Header";
import Sidebar from "./common/Sidebar";

import Dashboard from "./pages/dashboard";

import UserManagement from "./pages/userManagement";

import Events from "./pages/events";
import Event from "./pages/event";
import EventTimes from "./pages/eventTimes";

import ProjectManagement from "./pages/projectManagement";

import CompanySettings from "./pages/companySettings";
import DefaultQuestions from "./pages/defaultQuestions";
import EventNotifications from "./pages/eventNotifications";
import MainSettingsPage from "./pages/mainSettingsPage";
import PrivacyCentar from "./pages/privacyCentar";
import Translations from "./pages/translations";

import Web from "./pages/web";
import Video from "./pages/video";

const App = () => {
    const { user: currentUser, type: userType } = useSelector(
        (state) => state.user
    );
    return (
        <div className="app">
            <Router>
                {currentUser && <Header />}
                {currentUser &&
                    window.location.pathname !== "/" &&
                    window.location.pathname !== "/dashboard" && <Sidebar />}
                <div
                    className={`app__content ${
                        window.location.pathname == "/" ||
                        window.location.pathname == "/dashboard"
                            ? "wout-sidebar"
                            : ""
                    }`}
                >
                    <Switch>
                        <NonAuthRoute
                            exact
                            path={["/login"]}
                            component={Login}
                        />
                        <NonAuthRoute
                            exact
                            path={"/web/event/:id"}
                            component={Web}
                            pass={true}
                        />
                        <NonAuthRoute
                            exact
                            path={"/web/video/:id/:user"}
                            component={Video}
                            pass={true}
                        />
                        <ProtectedRoute
                            exact
                            path={["/", "/dashboard"]}
                            component={Dashboard}
                        />
                        <ProtectedRoute
                            exact
                            path="/user-management"
                            component={UserManagement}
                        />
                        <ProtectedRoute
                            exact
                            path="/events"
                            component={Events}
                        />
                        <ProtectedRoute
                            exact
                            path="/event/:id"
                            component={Event}
                        />
                        <ProtectedRoute
                            exact
                            path="/event-times/:id"
                            component={EventTimes}
                        />

                        <ProtectedRoute
                            exact
                            path="/project-management/:id"
                            component={ProjectManagement}
                        />

                        <ProtectedRoute
                            exact
                            path="/company-settings"
                            component={CompanySettings}
                        />
                        <ProtectedRoute
                            exact
                            path="/default-questions"
                            component={DefaultQuestions}
                        />
                        <ProtectedRoute
                            exact
                            path="/event-notifications/:id"
                            component={EventNotifications}
                        />
                        <ProtectedRoute
                            exact
                            path="/main-settings"
                            component={MainSettingsPage}
                        />
                        <ProtectedRoute
                            exact
                            path="/privacy-centar"
                            component={PrivacyCentar}
                        />
                        <ProtectedRoute
                            exact
                            path="/translations"
                            component={Translations}
                        />
                    </Switch>
                </div>
            </Router>
        </div>
    );
};

export default App;
