import { authHeader } from "./authHeader";
import { API_URL } from "../config";

const API = `${API_URL}/members`;

const create = (data) => {
    return fetch(`${API}/create`, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const all = (id) => {
    return fetch(`${API}/all/${id}`, {
        method: "GET",
        headers: authHeader(),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const filter = (id, filter) => {
    return fetch(`${API}/filter/${id}`, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(filter),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const update = (id, data) => {
    return fetch(`${API}/${id}`, {
        method: "PUT",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const bulkUpdate = async (ids, status) => {
    return fetch(`${API}/bulk/update`, {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify({
            ids: ids,
            status: status,
        }),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const membersService = {
    create,
    all,
    filter,
    bulkUpdate,
    update,
};

export default membersService;
