import React, { useState, useEffect } from "react";

const Dashboard = (props) => {
    return (
        <div className="home">
            <div className="container-xs">
                <div className="home__block">
                    <div className="home__info">
                        <h3>What's new?</h3>
                        <ul>
                            <li>
                                <b>Date:</b> DD/MM/YYYY
                            </li>
                            <li>
                                <b>Current version: </b> Name & Version ID
                            </li>
                        </ul>
                        <p>
                            <em>Lorem ipsum dolor sit amet</em>, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Nulla at volutpat
                            diam ut venenatis. Libero justo laoreet sit amet
                            cursus sit. Eu non diam phasellus vestibulum.
                            Faucibus vitae aliquet nec ullamcorper sit amet
                            risus. Sed sed risus pretium quam vulputate. Gravida
                            rutrum quisque non tellus. Interdum velit euismod in
                            pellentesque massa placerat duis ultricies lacus.
                            Vestibulum rhoncus est pellentesque elit. Rhoncus
                            urna neque viverra justo nec ultrices. Purus viverra
                            accumsan in nisl nisi scelerisque eu. Aenean euismod
                            elementum nisi quis eleifend quam adipiscing vitae
                            proin. Vivamus arcu felis bibendum ut tristique.
                            Duis at tellus at urna condimentum mattis
                            pellentesque. Amet tellus cras adipiscing enim. Ut
                            etiam sit amet nisl purus in. At erat pellentesque
                            adipiscing commodo elit at. Tellus in hac habitasse
                            platea dictumst.
                        </p>
                        <p>
                            <em>Ipsum faucibus vitae aliquet nec</em>
                            ullamcorper sit. Montes nascetur ridiculus mus
                            mauris vitae. Cursus sit amet dictum sit amet justo
                            donec enim diam. Vitae sapien pellentesque habitant
                            morbi tristique senectus et netus. Ac odio tempor
                            orci dapibus ultrices in iaculis nunc. Diam
                            phasellus vestibulum lorem sed risus ultricies. Sed
                            sed risus pretium quam vulputate dignissim
                            suspendisse in. Pellentesque id nibh tortor id
                            aliquet lectus proin. Congue mauris rhoncus aenean
                            vel elit scelerisque mauris. Risus commodo viverra
                            maecenas accumsan lacus vel facilisis volutpat.
                        </p>
                    </div>
                </div>
                <div className="settings event__settings-tab">
                    <div className="settings__list">
                        <div className="settings__list-items">
                            <div className="settings__list-item">
                                <div className="settings__list-item-head">
                                    <h4>Event Management</h4>
                                </div>
                                <div className="settings__list-item-content">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore mangna
                                        aliqua.
                                    </p>
                                </div>
                                <div className="settings__list-item-button">
                                    <a href="/events"> Go </a>
                                </div>
                            </div>
                            {/* <div className="settings__list-item">
                                <div className="settings__list-item-head">
                                    <h4>Survey Designer</h4>
                                </div>
                                <div className="settings__list-item-content">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore mangna
                                        aliqua.
                                    </p>
                                </div>
                                <div className="settings__list-item-button">
                                    <a href="#"> Go </a>
                                </div>
                            </div> */}
                            <div className="settings__list-item">
                                <div className="settings__list-item-head">
                                    <h4>Account Settings</h4>
                                </div>
                                <div className="settings__list-item-content">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore mangna
                                        aliqua.
                                    </p>
                                </div>
                                <div className="settings__list-item-button">
                                    <a href="/main-settings"> Go</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
