import React, { useState, useEffect } from "react";

import HomeIcon from "../../assets/img/home-icon-breadcrumb.svg";
import ArrowRightNormalGrayIcon from "../../assets/img/arrow-right-normal-gray.svg";
import SearchWhiteIcon from "../../assets/img/search-white.svg";

const Translations = (props) => {
    return (
        <div className="settings">
            <div className="settings__translations">
                <div className="translations__top">
                    <div className="breadcrumb">
                        <ul>
                            <li>
                                <a href="getstarted.php">
                                    <img src={HomeIcon} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Settings</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Languages</a>
                            </li>
                        </ul>
                    </div>
                    <div className="translations__top-actions">
                        <a href="#">Default Languages</a>
                        <a href="#">Upload Translations</a>
                    </div>
                </div>
                <div className="translations__block">
                    <div className="translations__box">
                        <div className="translations__form">
                            <select name="lf" id="">
                                <option value="English">English</option>
                            </select>
                            <img
                                src={ArrowRightNormalGrayIcon}
                                alt=""
                                className="tf-img"
                            />
                            <select name="lf" id="">
                                <option value="English">English</option>
                            </select>
                            <form action="">
                                <input
                                    type="text"
                                    placeholder="Enter search term"
                                />
                                <button>
                                    <img src={SearchWhiteIcon} alt="" />
                                </button>
                            </form>
                        </div>
                        <div className="translations__tabs">
                            <ul>
                                <li className="active">
                                    <h4>12</h4>
                                    <b>All</b>
                                </li>
                                <li>
                                    <h4>12</h4>
                                    <b>Untranslated</b>
                                </li>
                                <li>
                                    <h4>0</h4>
                                    <b>Translated</b>
                                </li>
                            </ul>
                        </div>
                        <div className="translations__content">
                            <div className="translations__list">
                                <div
                                    className="
								translations__item
								head--item
							"
                                >
                                    <div className="ti__small">
                                        <h5>Item</h5>
                                    </div>
                                    <div className="ti__big">
                                        <h5>Source Language</h5>
                                    </div>
                                    <div className="ti__big">
                                        <h5>Target Language</h5>
                                    </div>
                                    <div className="ti__small">
                                        <h5>Translated</h5>

                                        <div className="custom__checkbox">
                                            <label
                                                className="
											custom__checkbox-container
										"
                                            >
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="translations__item">
                                    <div className="ti__small">
                                        <h4>ITEM</h4>
                                    </div>
                                    <div className="ti__big">
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consetetur sadipscing elitr, sed
                                            diam nonumy eirmod tempor invidunt
                                            ut labore et dolore magna aliquyam
                                            erat, sed diam voluptua. At vero eos
                                            et accusam et justo duo dolores...
                                        </p>
                                    </div>
                                    <div className="ti__big">
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consetetur sadipscing elitr, sed
                                            diam nonumy eirmod tempor invidunt
                                            ut labore et dolore magna aliquyam
                                            erat, sed diam voluptua. At vero eos
                                            et accusam et justo duo dolores...
                                        </p>
                                    </div>
                                    <div className="ti__small">
                                        <div className="custom__checkbox">
                                            <label
                                                className="
											custom__checkbox-container
										"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked="checked"
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="translations__item">
                                    <div className="ti__small">
                                        <h4>ITEM</h4>
                                    </div>
                                    <div className="ti__big">
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consetetur sadipscing elitr, sed
                                            diam nonumy eirmod tempor invidunt
                                            ut labore et dolore magna aliquyam
                                            erat, sed diam voluptua. At vero eos
                                            et accusam et justo duo dolores...
                                        </p>
                                    </div>
                                    <div className="ti__big">
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consetetur sadipscing elitr, sed
                                            diam nonumy eirmod tempor invidunt
                                            ut labore et dolore magna aliquyam
                                            erat, sed diam voluptua. At vero eos
                                            et accusam et justo duo dolores...
                                        </p>
                                    </div>
                                    <div className="ti__small">
                                        <div className="custom__checkbox">
                                            <label
                                                className="
											custom__checkbox-container
										"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked="checked"
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="translations__item">
                                    <div className="ti__small">
                                        <h4>ITEM</h4>
                                    </div>
                                    <div className="ti__big">
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consetetur sadipscing elitr, sed
                                            diam nonumy eirmod tempor invidunt
                                            ut labore et dolore magna aliquyam
                                            erat, sed diam voluptua. At vero eos
                                            et accusam et justo duo dolores...
                                        </p>
                                    </div>
                                    <div className="ti__big">
                                        <p>
                                            Lorem ipsum dolor sit amet,
                                            consetetur sadipscing elitr, sed
                                            diam nonumy eirmod tempor invidunt
                                            ut labore et dolore magna aliquyam
                                            erat, sed diam voluptua. At vero eos
                                            et accusam et justo duo dolores...
                                        </p>
                                    </div>
                                    <div className="ti__small">
                                        <div className="custom__checkbox">
                                            <label
                                                className="
											custom__checkbox-container
										"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked="checked"
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Translations;
