import { authHeader } from "./authHeader";
import { API_URL } from "../config";

const API = `${API_URL}/events`;

const create = (data) => {
    return fetch(`${API}/create`, {
        method: "POST",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const createDate = (data) => {
    return fetch(`${API}/create-date`, {
        method: "POST",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const videoGrant = (data) => {
    return fetch(`${API}/video-grant`, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const cloneDate = (id, data) => {
    return fetch(`${API}/clone/${id}`, {
        method: "POST",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const update = (id, data) => {
    return fetch(`${API}/${id}`, {
        method: "PUT",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const addTime = (id, data) => {
    return fetch(`${API}/add-time/${id}`, {
        method: "PUT",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const get = (id) => {
    return fetch(`${API}/single/${id}`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const getTimes = (id) => {
    return fetch(`${API}/times/${id}`, {
        method: "GET",
        headers: authHeader(),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const dates = (id) => {
    return fetch(`${API}/single/${id}/dates`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const all = (id) => {
    return fetch(`${API}/all/${id}`, {
        method: "GET",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const updateDate = (id, data) => {
    return fetch(`${API}/date/${id}`, {
        method: "PUT",
        headers: authHeader(true),
        body: JSON.stringify(data),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};
const deleteDate = (id) => {
    return fetch(`${API}/date/${id}`, {
        method: "DELETE",
        headers: authHeader(true),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((r) => {
                throw Error(r.message);
            });
        }

        return res.json();
    });
};

const eventsService = {
    create,
    get,
    update,
    all,
    createDate,
    addTime,
    dates,
    cloneDate,
    updateDate,
    deleteDate,
    getTimes,
    videoGrant,
};

export default eventsService;
