import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./messageSlice";

import userService from "../services/user.service";

const user = JSON.parse(localStorage.getItem("user"));
const token = localStorage.getItem("token");

export const userRegister = createAsyncThunk(
    "users/register",
    async (data, thunkAPI) => {
        try {
            const response = await userService.register(data).catch((e) => {
                throw Error(e);
            });
            thunkAPI.dispatch(setMessage(response.message));
            return response;
        } catch (error) {
            const message =
                error.message ||
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.error.toString() ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const userLogin = createAsyncThunk(
    "users/login",
    async (data, thunkAPI) => {
        try {
            const resData = await userService.login(data).catch((e) => {
                throw Error(e);
            });
            return {
                user: JSON.stringify(resData.user),
                token: resData.token,
            };
        } catch (error) {
            const message =
                error.message ||
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const userLogout = createAsyncThunk("users/logout", async () => {
    await userService.logout();
});

const initialState =
    user && token
        ? {
              isLoggedIn: true,
              user,
              token,
          }
        : {
              isLoggedIn: false,
              user: null,
              token: null,
          };

export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: {
        [userRegister.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
        },
        [userRegister.rejected]: (state, action) => {
            state.isLoggedIn = false;
        },
        [userLogin.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.type = "user";
        },
        [userLogin.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            state.type = null;
        },
        [userLogout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            state.type = null;
        },
    },
});

const { reducer } = userSlice;
export default reducer;
