import React, { useState, useEffect, useRef } from "react";
import moreIcon from "../assets/img/more.svg";
import videoGraphyIcon from "../assets/img/video-graphy.svg";

const Participant = ({ participant }) => {
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);

    const videoRef = useRef();
    const audioRef = useRef();

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        const trackSubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((videoTracks) => [...videoTracks, track]);
            } else {
                setAudioTracks((audioTracks) => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((videoTracks) =>
                    videoTracks.filter((v) => v !== track)
                );
            } else {
                setAudioTracks((audioTracks) =>
                    audioTracks.filter((a) => a !== track)
                );
            }
        };

        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        participant.on("trackSubscribed", trackSubscribed);
        participant.on("trackUnsubscribed", trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);

            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    return (
        <div class="video__content-participant">
            <div class="video__content-participant-image">
                <video
                    style={{ width: "100%", borderRadius: 5 }}
                    ref={videoRef}
                    autoPlay={true}
                />
                <audio ref={audioRef} autoPlay={true} muted={false} />
            </div>
            {/* <div class="video__content-participant-name">
                <h4>Erlina Watson</h4>
                <img src={videoGraphyIcon} alt="" />
            </div>
            <div class="video__content-participant-more">
                <div class="p-more-button">
                    <img src={moreIcon} alt="" />
                </div>
            </div> */}
        </div>
    );
};
export default Participant;
