import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";

import homeIcon from "../../assets/img/home-icon-breadcrumb.svg";
import playWhiteIcon from "../../assets/img/play-white.svg";
import closeWhiteIcon from "../../assets/img/close-white.svg";

import eventsService from "../../services/event.service";
import invitesService from "../../services/invite.service";

import Modal from "../../common/Modal";
import { EVENT_URL } from "../../config";

const Event = (props) => {
    const eventId = props.match.params.id;
    const { user } = useSelector((state) => state.user);
    const ref = useRef();

    const [event, setEvent] = useState({});
    const [invites, setInvites] = useState([]);
    const [showEditEvent, setShowEditEvent] = useState(false);
    const [showEditExit, setShowEditExit] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [inviteNew, setInviteNew] = useState(false);
    const [inviteEdit, setInviteEdit] = useState(false);
    const [userToEdit, setUserToEdit] = useState("");

    const [eventInitialValues, setEventInitialValues] = useState({
        name: "",
        description: "",
    });
    const [exitInitialValues, setExitInitialValues] = useState({
        variation: "internal",
        url: "",
    });
    const [inviteInitialValues, setInviteInitialValues] = useState({
        name: "",
        event: eventId,
        email: "",
        role: "",
    });
    const [editInviteInitialValues, setEditInviteInitialValues] = useState({
        name: "",
        email: "",
        role: "",
    });

    const eventValidationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required"),
    });

    const editEvent = async (formValue) => {
        await eventsService.update(eventId, formValue).then((resJson) => {
            setShowEditEvent(false);
            window.location.reload();
        });
    };
    const editExit = async (formValue) => {
        await eventsService
            .update(eventId, { exit_page: formValue })
            .then((resJson) => {
                setShowEditExit(false);
                window.location.reload();
            });
    };
    const sendInvite = async (formValue) => {
        formValue.event = eventId;
        await invitesService.create(formValue).then((resJson) => {
            setInvites([...invites, resJson]);
            setInviteNew(false);
        });
    };
    const editInvite = async (formValue) => {
        await invitesService.update(userToEdit, formValue).then((resJson) => {
            setInviteEdit(false);
            window.location.reload();
        });
    };

    const editUser = (item) => {
        setEditInviteInitialValues({
            name: item.name,
            email: item.email,
            role: item.role,
        });
        setUserToEdit(item._id);
        setInviteEdit(true);
    };

    const deleteInvite = async (id) => {
        await invitesService.remove(id).then((resJson) => {
            window.location.reload();
        });
    };

    const sendEmail = async () => {
        await invitesService.send().then((resJson) => {});
    };

    useEffect(() => {
        void (async function fetchData() {
            await eventsService.get(eventId).then(async (resJson) => {
                setEvent(resJson);
                setEventInitialValues({
                    name: resJson.name || "",
                    description: resJson.description || "",
                });
                setExitInitialValues({
                    variation: resJson.exit_page.variation || "internal",
                    url: resJson.exit_page.url || "",
                });
            });
            await invitesService.all(eventId).then(async (resJson) => {
                setInvites(resJson);
            });
        })();
    }, []);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (showSidebar && ref.current && !ref.current.contains(e.target)) {
                setShowSidebar(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showSidebar]);

    return (
        <div className="settings event__settings-tab">
            <Modal show={showEditEvent}>
                <div className="app__modal-title">
                    <h4>Event Name and Description</h4>
                    <img className="modal--close" src={closeWhiteIcon} alt="" />
                </div>
                <div className="app__modal-content">
                    <Formik
                        enableReinitialize
                        initialValues={eventInitialValues}
                        validationSchema={eventValidationSchema}
                        onSubmit={editEvent}
                    >
                        {({ values, touched, errors, handleChange }) => {
                            return (
                                <Form>
                                    <div className="custom__input">
                                        <label for="">Event Name </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="Enter Event Name"
                                        />
                                    </div>
                                    <div className="app__modal--textarea">
                                        <label for="">
                                            Event Name and Description
                                        </label>
                                        <textarea
                                            type="text"
                                            name="description"
                                            style={{ height: 120 }}
                                            value={values.description}
                                            onChange={handleChange}
                                            placeholder="Enter Event Description"
                                        ></textarea>
                                    </div>
                                    <div className="app__modal-bottom">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                setShowEditEvent(false)
                                            }
                                            className="button-o"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" name="submit">
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
            <Modal show={showEditExit}>
                <div className="app__modal-title">
                    <h4>Exit Page</h4>
                    <img className="modal--close" src={closeWhiteIcon} alt="" />
                </div>
                <div className="app__modal-content">
                    <Formik
                        enableReinitialize
                        initialValues={exitInitialValues}
                        onSubmit={editExit}
                    >
                        {({ values, touched, errors, handleChange }) => {
                            return (
                                <Form>
                                    <div
                                        style={{ marginBottom: "20px" }}
                                        className="custom__select-new"
                                    >
                                        <select
                                            name="variation"
                                            defaultValue={values.variation}
                                            onChange={handleChange}
                                        >
                                            <option
                                                selected={
                                                    values.variation ===
                                                    "internal"
                                                }
                                                value="internal"
                                            >
                                                Internal confirmation page
                                            </option>
                                            <option
                                                selected={
                                                    values.variation ===
                                                    "external"
                                                }
                                                value="external"
                                            >
                                                Redirect to external page
                                            </option>
                                        </select>
                                    </div>
                                    {values.variation === "external" && (
                                        <div className="custom__input">
                                            <label for="">Event URL </label>
                                            <input
                                                type="text"
                                                name="url"
                                                value={values.url}
                                                onChange={handleChange}
                                                placeholder="https://"
                                            />
                                        </div>
                                    )}
                                    <div className="app__modal-bottom">
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                setShowEditExit(false);
                                            }}
                                            className="button-o"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" name="submit">
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
            <Modal show={inviteNew}>
                <div class="app__modal-box">
                    <div class="app__modal-title">
                        <h4>Invite New User</h4>
                        <img class="modal--close" src={closeWhiteIcon} alt="" />
                    </div>
                    <div class="app__modal-content">
                        <Formik
                            initialValues={inviteInitialValues}
                            onSubmit={sendInvite}
                        >
                            {({ values, touched, errors, handleChange }) => {
                                return (
                                    <Form>
                                        <div class="app__modal-big-input">
                                            <label for="">First Name</label>
                                            <input
                                                name="first_name"
                                                value={values.first_name}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div class="app__modal-big-input">
                                            <label for="">Last Name</label>
                                            <input
                                                name="last_name"
                                                value={values.last_name}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div class="app__modal-big-input">
                                            <label for="">Email</label>
                                            <input
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div class="app__modal-big-select">
                                            <label for="">Role</label>
                                            <div class="custom__select">
                                                <select
                                                    name="role"
                                                    onChange={handleChange}
                                                    id=""
                                                >
                                                    <option
                                                        selected
                                                        value="administrator"
                                                    >
                                                        Administrator
                                                    </option>
                                                    <option value="manager">
                                                        Manager
                                                    </option>
                                                    <option value="observer">
                                                        Observer
                                                    </option>
                                                    <option value="translator">
                                                        Translator
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="app__modal-bottom">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    setInviteNew(false)
                                                }
                                                class="button-o"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" name="submit">
                                                Send Invite
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </Modal>
            <Modal show={inviteEdit}>
                <div class="app__modal-box">
                    <div class="app__modal-title">
                        <h4>Edit Invited User</h4>
                        <img class="modal--close" src={closeWhiteIcon} alt="" />
                    </div>
                    <div class="app__modal-content">
                        <Formik
                            enableReinitialize
                            initialValues={editInviteInitialValues}
                            onSubmit={editInvite}
                        >
                            {({ values, touched, errors, handleChange }) => {
                                return (
                                    <Form>
                                        <div class="app__modal-big-input">
                                            <label for="">First name</label>
                                            <input
                                                name="first_name"
                                                value={values.first_name}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div class="app__modal-big-input">
                                            <label for="">Last Name</label>
                                            <input
                                                name="last_name"
                                                value={values.last_name}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div class="app__modal-big-input">
                                            <label for="">Email</label>
                                            <input
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                type="text"
                                            />
                                        </div>
                                        <div class="app__modal-big-select">
                                            <label for="">Role</label>
                                            <div class="custom__select">
                                                <select
                                                    name="role"
                                                    onChange={handleChange}
                                                    defaultValue={values.role}
                                                    id=""
                                                >
                                                    <option
                                                        selected={
                                                            values.role ===
                                                            "administrator"
                                                                ? true
                                                                : false
                                                        }
                                                        value="administrator"
                                                    >
                                                        Administrator
                                                    </option>
                                                    <option
                                                        selected={
                                                            values.role ===
                                                            "manager"
                                                                ? true
                                                                : false
                                                        }
                                                        value="manager"
                                                    >
                                                        Manager
                                                    </option>
                                                    <option
                                                        selected={
                                                            values.role ===
                                                            "observer"
                                                                ? true
                                                                : false
                                                        }
                                                        value="observer"
                                                    >
                                                        Observer
                                                    </option>
                                                    <option
                                                        selected={
                                                            values.role ===
                                                            "translator"
                                                                ? true
                                                                : false
                                                        }
                                                        value="translator"
                                                    >
                                                        Translator
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="app__modal-bottom">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    setInviteEdit(false)
                                                }
                                                class="button-o"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" name="submit">
                                                Save
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </Modal>
            <div className="event__settings-tab-top">
                <div className="breadcrumb">
                    <ul>
                        <li>
                            <a href="/">
                                <img src={homeIcon} alt="" />
                            </a>
                        </li>
                        <li>
                            <a href="/events">Events</a>
                        </li>
                        <li>
                            <a href={`/event/${event?._id}`}>{event?.name}</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Event Settings</a>
                        </li>
                    </ul>
                </div>
                <div className="event__settings-buttons">
                    <div className="custom__select">
                        <select name="" id="">
                            <option value="">View Event Page</option>
                        </select>
                        <div
                            onClick={() => {
                                window
                                    .open(`${EVENT_URL}/${eventId}`, "_blank")
                                    .focus();
                            }}
                            className="custom__select-button"
                        >
                            <img src={playWhiteIcon} alt="" />
                        </div>
                    </div>
                    <button
                        onClick={() =>
                            props.history.push(
                                `/project-management/${event?._id}`
                            )
                        }
                    >
                        Show Results
                    </button>
                    <button>Download Results</button>
                </div>
            </div>
            <div className="settings__list">
                <div className="settings__list-items">
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>{event?.name}</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>{event?.description || "No description"}</p>
                        </div>
                        <div className="settings__list-item-button">
                            <a onClick={() => setShowEditEvent(true)} href="#">
                                Edit Name And Description
                            </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Event Sessions</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore mangna aliqua.
                            </p>
                            <div className="inline__list">
                                <div className="inline__list-item">
                                    <h5>0</h5>
                                    <span>Dates</span>
                                </div>
                                <div className="inline__list-item">
                                    <h5>0</h5>
                                    <span>Sessions</span>
                                </div>
                                <div className="inline__list-item">
                                    <h5>0</h5>
                                    <span>Slots</span>
                                </div>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href={`/event-times/${eventId}`}>
                                {" "}
                                Edit Sessions{" "}
                            </a>
                        </div>
                    </div>
                    {/* <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Event Timezones</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Default Timezone:</b>
                                        <span>
                                            {event?.timezones?.default_zone ||
                                                "None"}
                                        </span>
                                    </li>
                                    <li>
                                        <b>Event Timezones:</b>

                                        <span>
                                            {event?.timezones?.all.map(
                                                (item, index) => {
                                                    return item;
                                                }
                                            )}
                                        </span>
                                        {event?.timezones?.all.length < 1 && (
                                            <span>None</span>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#"> Edit Timezones </a>
                        </div>
                    </div> */}
                    {/* <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Event Languages & Translations</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Default Language:</b>
                                        <span>[DEFAULT LANGUAGE]</span>
                                    </li>
                                    <li>
                                        <b>Event Language:</b>
                                        <span>[EVENT LANGUAGES]</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href="#">Edit Languages & Translations</a>
                        </div>
                    </div> */}
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Exit Page</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Exit Page:</b>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {event?.exit_page?.variation}
                                        </span>
                                    </li>
                                    {event?.exit_page?.variation ===
                                        "external" && (
                                        <li>
                                            <b>Destination:</b>
                                            <span>{event?.exit_page?.url}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a onClick={() => setShowEditExit(true)} href="#">
                                Edit Exit Page
                            </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Moderator & Observers</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Moderator:</b>
                                        <span>{user.name}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="inline__list">
                                <div className="inline__list-item">
                                    <h5>{invites.length}</h5>
                                    <span>Observers</span>
                                </div>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a onClick={() => setShowSidebar(true)} href="#">
                                {" "}
                                Edit Moderator
                            </a>
                        </div>
                    </div>
                    <div className="settings__list-item">
                        <div className="settings__list-item-head">
                            <h4>Notifications</h4>
                        </div>
                        <div className="settings__list-item-content">
                            <div className="normal__list">
                                <ul>
                                    <li>
                                        <b>Confirmation:</b>
                                        <span>
                                            {event?.notifications?.confirmation
                                                ?.email && "Email"}{" "}
                                            {event?.notifications?.confirmation
                                                ?.sms && "SMS"}
                                        </span>
                                    </li>
                                    <li>
                                        <b>Reminder:</b>
                                        <span>
                                            {event?.notifications?.reminder
                                                ?.email && "Email"}{" "}
                                            {event?.notifications?.reminder
                                                ?.sms && "SMS"}
                                        </span>
                                    </li>
                                    <li>
                                        <b>Follow Up:</b>
                                        <span>
                                            {event?.notifications?.follow_up
                                                ?.email && "Email"}{" "}
                                            {event?.notifications?.follow_up
                                                ?.sms && "SMS"}
                                        </span>
                                    </li>
                                    <li>
                                        <b>Cancellation:</b>
                                        <span>
                                            {event?.notifications?.cancellation
                                                ?.email && "Email"}{" "}
                                            {event?.notifications?.cancellation
                                                ?.sms && "SMS"}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="settings__list-item-button">
                            <a href={`/event-notifications/${eventId}`}>
                                {" "}
                                Edit Notifications{" "}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                ref={ref}
                class={`app__right ${!showSidebar && "app__right--hidden"}`}
            >
                <div className="app__right-content">
                    <div className="app__right-box">
                        <h4>Send Link To Selected</h4>
                        <div className="app__right-box-button">
                            <button onClick={() => sendEmail()}>
                                Send Email
                            </button>
                            <button>Send SMS</button>
                        </div>
                    </div>
                    <div className="app__right-box">
                        <h4>Assign Moderator</h4>
                        <div className="custom__select">
                            <select name="" id="">
                                <option value="asc">{user.name}</option>
                            </select>
                        </div>
                        <div className="custom__check">
                            <div className="custom__checkbox">
                                <label className="custom__checkbox-container">
                                    <input type="checkbox" checked="checked" />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="custom__check-content">
                                <h5>Select Moderator</h5>
                            </div>
                        </div>
                    </div>
                    <div className="app__right-box">
                        <h4>Assign Observers</h4>
                        {invites.map((item, index) => {
                            return (
                                <div className="custom__check">
                                    <div className="custom__checkbox">
                                        <label className="custom__checkbox-container">
                                            <input
                                                type="checkbox"
                                                name="enabled"
                                                checked={`${
                                                    item.enabled
                                                        ? "checked"
                                                        : false
                                                }`}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="custom__check-content">
                                        <h5>{item.name}</h5>
                                        <h5>{item.email}</h5>
                                        <div className="custom__check-buttons">
                                            <button
                                                onClick={() => editUser(item)}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() =>
                                                    deleteInvite(item._id)
                                                }
                                                className="cc--delete"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <div className="new__observer">
                            <a onClick={() => setInviteNew(true)} href="#">
                                + Add New Observer Here
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Event;
